import React, {useContext} from 'react'
import {Button, Col, Row} from "reactstrap"
import {assetFullName, cryptoIconsMapper, isTwoPrecision, prettyDate} from "@metronic/helpers/utils.jsx"
import {ModalContext} from "@metronic/Components/Modal/index.jsx"
import {usePage} from "@inertiajs/react"

const ConfirmationInformationCard = ({order}) => {
  const {closeModal} = useContext(ModalContext)
  const userInfo = usePage().props?.auth?.user

  return (<>
      <Row className="g-5 g-xl-10">
        <Col className="col-12">
          <Row>
            <h1 className="w-100 text-center mb-10">Order in queue</h1>

            <p className="text-center fs-3">
              Dear <strong className="text-primary">{userInfo.name}</strong> your trade request
              created on <strong>{prettyDate(order.created_at)}</strong> is currently being processed
            </p>

            <div className="d-flex flex-column flex-lg-row align-items-center mt-10">
              <div
                className="d-flex flex-column align-items-center justify-content-center border
                      border-gray-300 bg-light-warning p-3 rounded-2 w-100 py-5"
              >
                <span className="fs-4 text-gray-700 mb-2 fw-bold text-center">Amount and asset you are selling</span>
                <div className="d-flex align-items-center">
                  <span className="fs-5 fw-bold me-2">{isTwoPrecision(order.sell_asset.currency.code, order.sell_price)}</span>
                  <img
                    src={cryptoIconsMapper[order.sell_asset.currency.code]}
                    alt={order.sell_asset.currency.code}
                    className="w-25px me-2"
                  />
                  <span className="fs-5 fw-bold">{assetFullName[order.sell_asset.currency.code]}</span>
                  <i className="bi bi-arrow-up-right-square-fill text-danger fw-bold fs-4 ms-3"></i>
                </div>
              </div>

              <div className="d-flex align-items-center">
                <i className="bi bi-arrow-left-right fs-1 fw-bold text-success mx-15 d-none d-lg-block"></i>
                <i className="bi bi-arrow-down-up fs-1 fw-bold text-success mx-3 d-block d-lg-none py-3"></i>
              </div>

              <div
                className="d-flex flex-column align-items-center border border-gray-300 justify-content-center
                      bg-light-success p-3 rounded-2 w-100 py-5"
              >
                <span className="fs-4 text-gray-700 mb-2 fw-bold text-center">Amount and asset you will receive</span>
                <div className="d-flex align-items-center">
                  <span className="fs-5 fw-bold me-2">{isTwoPrecision(order.buy_asset.currency.code, order.buy_price)}</span>
                  <img
                    src={cryptoIconsMapper[order.buy_asset.currency.code]}
                    alt={order.buy_asset.currency.code}
                    className="w-25px me-2"
                  />
                  <span className="fs-5 fw-bold">{assetFullName[order.buy_asset.currency.code]}</span>
                  <i className="bi bi-arrow-down-right-square-fill text-success fw-bold fs-4 ms-3"></i>
                </div>
              </div>
            </div>
          </Row>

          <Row className="mt-10">
            <Col className="w-100 col-12">
              <div className="border border-light-info bg-light-primary rounded-2 p-4">
                <p className="text-primary fs-5 text-center mb-0">
                  Your order has been successfully created and is now in the queue. We are currently searching for a
                  buyer who is interested in your offer. Once a match is found, you will receive further instructions to
                  complete the transaction.
                </p>
                <p className="text-primary fs-5 text-center mb-0 pt-3">
                  Please note that this process may take some time depending on the availability of matching buyers.
                  Stay tuned for updates, and thank you for your patience!
                </p>
              </div>
            </Col>
          </Row>

          <div className="w-100 mt-5 text-end">
            <Button type="button" className="btn btn-warning" onClick={closeModal}>
              Close
            </Button>
          </div>
        </Col>
      </Row>
  </>)
}

export default ConfirmationInformationCard
