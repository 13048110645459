import React from "react"
import {PageTitle} from "@metronic/_metronic/layout/core"
import {DashboardContent} from "@metronic/Components"
import {Head} from "@inertiajs/react"

const DashboardWrapper = () => {
  return (
    <>
      <Head title={'Dashboard | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>Dashboard</PageTitle>
      <DashboardContent/>
    </>
  )
}

export default DashboardWrapper
