
export default {
  "Last 5 Transactions": "Last 5 Transactions",
  "Type": "Type",
  "Created date": "Created date",
  "Base Asset": "Base Asset",
  "Quote Asset": "Quote Asset",
  "Amount": "Amount",
  "USD Amount": "USD Amount",
  "Fee Pct": "Fee Pct",
  "Status": "Status",
  "Executed": "Executed",
  "Pending": "Pending",
  "Declined": "Declined",
  "Partial": "Partial",
  "Paid": "Paid",
  "Date": "Date",
  "Invoice ID": "Invoice ID",
  "Invoicing Date": "Invoicing Date",
  "Due Date": "Due Date",
  "Payout Asset": "Payout Asset",
  "Actions": "Actions",
  "Top Up": "Top Up",
  "Pay from balance": "Pay from balance",
  "Loading": "Loading",
  "There are no records to display": "There are no records to display",
  "Rows per page:": "Rows per page:",
  "Loading...": "Loading..."
}
