import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {KTIcon} from '../../../../helpers'
import {useLayout} from '../../../core'
import {HeaderDefault} from '../Header/HeaderDefault'
import {useThemeMode} from "../../../../partials/layout/theme-mode/ThemeModeProvider"
import {PlannerTitle} from "../page-title/PlannerTitle"
import {TopbarPlanner} from "../TopBar/TopbarPlanner"
import {isStickyHeader} from '@metronic/helpers/utils'

const logosUrl = '/storage/brand_logos/'
const logoLightUrl = logosUrl + 'logo-light.png'
const logoDarkSmUrl = logosUrl + 'logo-dark.png'
const logoDarkUrl = logosUrl + 'logo-dark.png'

export function HeaderWrapperPlanner() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  const {mode} = useThemeMode()
  const [logoVariant, setLogoVariant] = useState('')
  const isSticky = isStickyHeader()

  useEffect(() => {
    if (mode === 'dark') {
      setLogoVariant(logoLightUrl)
    } else {
      setLogoVariant(logoDarkUrl)
    }
  }, [mode])

  return (
    <div
      id='kt_header'
      className={clsx('header d-print-none', classes.header.join(' '), 'align-items-stretch')}
      data-kt-sticky='true'
      data-kt-sticky-name='header'
      data-kt-sticky-offset="{default: '200px', lg: '300px'}"
      {...attributes.headerMenu}
    >
      <div className={clsx('container-xxl', !isSticky && 'h-85px', 'd-flex align-items-center')}>
        {/* begin::Aside mobile toggle */}
        {aside.display && (
          <div className={clsx('d-flex align-items-center d-lg-none me-2')} title='Show aside menu'>
            <div
              className='btn btn-icon btn-color-gray-700 btn-active-color-primary btn-outline btn-active-bg-light w-30px h-30px w-lg-40px h-lg-40px'
              id='kt_aside_mobile_toggle'
            >
              <KTIcon iconName='abstract-14' className='fs-1' />
            </div>
          </div>
        )}

        {/*<div*/}
        {/*  className={clsx('topbar align-items-center ms-n2 me-3', isSticky ? 'd-none' : 'd-lg-none')}*/}
        {/*  title='Show aside menu'*/}
        {/*>*/}
        {/*  <div*/}
        {/*    className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'*/}
        {/*    id='kt_header_menu_mobile_toggle'*/}
        {/*  >*/}
        {/*    <KTIcon iconName='abstract-14' className='fs-2x'/>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <div className='header-logo me-1 flex-grow-1 flex-lg-grow-0'>
          {/*<Link href={route('/dashboard')}>*/}
          <img
            alt=''
            src={logoVariant || ''}
            className='logo-default h-30px h-lg-40px'
          />
          <img
            alt=''
            src={logoVariant || ''}
            className='logo-sticky h-30px h-lg-40px'
          />
          {/*</Link>*/}
        </div>

        {/* begin::Wrapper */}
        <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
          {/* begin::Navbar */}
          {header.left === 'menu' && (
            <div className='d-flex align-items-stretch' id='kt_header_nav'>
              {/*<HeaderDefault/>*/}
            </div>
          )}

          {header.left === 'page-title' && (
            <div className='d-flex align-items-center' id='kt_header_nav'>
              <PlannerTitle/>
            </div>
          )}

          <div className='d-flex align-items-stretch flex-shrink-0'>
            <TopbarPlanner/>
          </div>
        </div>
        {/* end::Wrapper */}
      </div>

      <div className="separator w-100"></div>

      <div className={clsx(isSticky ? 'd-none' : ' d-none d-lg-block', 'd-flex align-items-center container-xxl')}>
        <div className='d-flex align-items-stretch h-lg-75px' id='kt_header_nav'>
          {/* The PLANNER theme uses MenuInnerDefault and HeaderDefault instead of personal */}

          <HeaderDefault/>
        </div>
      </div>
    </div>
  )
}
