import React from "react";
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";

const BaseActionDropdown = (props) => {
  return (
    <React.Fragment>
      <UncontrolledDropdown>
        <DropdownToggle tag="button" className="btn btn-secondary" id="dropdownMenuButton">
          Actions <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>
        <DropdownMenu>
          {props.children}
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  )
}

export default BaseActionDropdown;
