
export default {
  "Subject field is required": "Subject field is required",
  "Message field is required": "Message field is required",
  "Select a ticket from the existing ones": "Select a ticket from the existing ones",
  "or create a new one": "or create a new one",
  "Chats list": "Chats list",
  "Create New Chat": "Create New Chat",
  "Waiting": "Waiting",
  "Open": "Open",
  "Closed": "Closed",
  "Answered": "Answered",
  "Chat": "Chat",
  "Create new ticket": "Create new ticket",
  "Subject": "Subject",
  "Message": "Message",
  "Enter your subject": "Enter your subject",
  "Type your message...": "Type your message...",
  "Please place here full description of your issue.": "Please place here full description of your issue.",
  "Create ticket": "Create ticket",
  "Close ticket": "Close ticket",
  "Support Team": "Support Team",
  "Opened": "Opened",
  "Copy": "Copy"
}
