import React, {useEffect, useState} from 'react'
import ApexCharts from 'apexcharts'

const categories = [
  '52 Week High',
  '52 Week Low',
  '52 Week Average',
  '200-Day Moving Average',
  '50-Day Moving Average',
]

const StockPriceComparisonChart = ({securityTickerData}) => {
  const [seriesData, setSeriesData] = useState([])

  useEffect(() => {
    if (securityTickerData && securityTickerData.stock_overview_data) {
      let data = securityTickerData.stock_overview_data?.data

      if (data) {
        setSeriesData(
          [
            data['52 Week High'],
            data['52 Week Low'],
            ((Number(data['52 Week High']) + Number(data['52 Week Low'])) / 2).toFixed(2),
            data['200 Day Moving Average'],
            data['50 Day Moving Average'],
          ]
        )
      }
    } else {
      setSeriesData([])
    }
  }, [securityTickerData])


  useEffect(() => {
    if (seriesData && seriesData.length) {
      const options = {
        chart: {
          type: 'bar',
          height: 350,
        },
        series: [
          {
            name: 'Price',
            data: seriesData,
          },
        ],
        xaxis: {
          categories: categories,
        },
        // title: {
        //   text: 'Key Price Metrics',
        // },
      }

      const chart = new ApexCharts(document.querySelector('#chart'), options)
      chart.render()

      return () => {
        chart.destroy()
      }
    }
  }, [seriesData])

  return (
    (seriesData && seriesData.length) ?
    <div className="d-flex flex-column mt-5">
      <h4 className="text-gray-700 border-bottom-dashed border-1 border-gray-300 pb-1">Key Price Metrics</h4>
      <div id="chart"></div>
    </div> : null
  )
}

export default StockPriceComparisonChart
