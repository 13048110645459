import React from 'react'

const DocumentPreviewModal = (url) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <img src={url.url} alt='document' className="w-auto"/>
    </div>
  )
}

export default DocumentPreviewModal
