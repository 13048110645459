
export default {
  "Menu": "Menu",
  "Dashboard": "Dashboard",
  "Exchange": "Exchange",
  "Deposit": "Deposit",
  "Credit Card": "Credit Card",
  "Crypto": "Crypto",
  "Bank Details": "Bank Details",
  "Withdraw": "Withdraw",
  "Bank Transfer": "Bank Transfer",
  "Transactions": "Transactions",
  "Invoices": "Invoices",
  "Support": "Support",
  "More": "More"
}
