
import React from 'react'
import {KTIcon} from '../../../helpers'

type Props = {
  className: string
  color?: string
  svgIcon: string
  iconColor: string
  title: string
  titleColor?: string
  description: string
  descriptionColor?: string
  descriptionFontSize?: string
  descriptionFontWeight?: string
}

const StatisticsWidget5: React.FC<Props> = ({
  className,
  color,
  svgIcon,
  iconColor,
  title,
  titleColor,
  description,
  descriptionColor,
  descriptionFontSize,
  descriptionFontWeight,
}) => {
  return (
    <div className={`card bg-${color} hoverable ${className}`}>
      <div className='card-body p-1 p-sm-3'>
        <KTIcon iconName={svgIcon} className={`text-${iconColor} fs-3x ms-n1`} />

        <div className={`text-${titleColor} fw-bold text-nowrap fs-2 mb-2 mt-2`}>{title}</div>

        <div className={`fw-${descriptionFontWeight} fs-${descriptionFontSize} text-${descriptionColor}`}>{description}</div>
      </div>
    </div>
  )
}

export {StatisticsWidget5}
