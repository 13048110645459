import {createRoot} from 'react-dom/client'
import {createInertiaApp} from '@inertiajs/react'
import {MainLight, MainDefault, MainPlanner} from "@metronic/Pages/MainVariations"
import './i18n'
// import {AppVariant} from "@metronic/Enums/AppVariants/AppVariant.js"

const withoutLayout = ['LogIn', 'TwoFactor', 'Unsubscribe', 'ErrorPage']
const VITE_APP_VARIANT = import.meta.env.VITE_APP_VARIANT

//Styles

if (VITE_APP_VARIANT === 'light') {
  import ('@metronic/_metronic/assets/sass/style_react/style.react.light.scss')
} else if (VITE_APP_VARIANT === 'planner') {
  import ('@metronic/_metronic/assets/sass/style_react/style.react.planner.scss')
} else if (VITE_APP_VARIANT === 'plannerOrange') {
  import ('@metronic/_metronic/assets/sass/style_react/style.react.planner.orange.scss')
} else {
  import ('@metronic/_metronic/assets/sass/style_react/style.react.default.scss')
}

import '@metronic/_metronic/assets/fonticon/fonticon.scss'
import '@metronic/_metronic/assets/keenicons/duotone/style.css'
import '@metronic/_metronic/assets/keenicons/outline/style.css'
import '@metronic/_metronic/assets/keenicons/solid/style.css'
import {get} from "@metronic/helpers/api_helper.jsx"

if (VITE_APP_VARIANT === 'light') {
  import ('@metronic/_metronic/assets/sass/style/style.light.scss')
} else if (VITE_APP_VARIANT === 'planner') {
  import ('@metronic/_metronic/assets/sass/style/style.planner.scss')
} else if (VITE_APP_VARIANT === 'plannerOrange') {
  import ('@metronic/_metronic/assets/sass/style/style.planner.orange.scss')
} else {
  import ('@metronic/_metronic/assets/sass/style/style.default.scss')
}

createInertiaApp({
  resolve: name => {
    const pages = import.meta.glob('./Pages/**/*.jsx', {eager: true})
    let page = pages[`./Pages/${name}.jsx`]

    if (!page || !page.default) {
      throw new Error(`Page ${name} does not have a default export.`)
    }

    if (withoutLayout.includes(name)) {
      return page
    }

    switch (VITE_APP_VARIANT) {
      case 'light':
        page.default.layout = page.default.layout || ((page) => <MainLight children={page} />)
        break
      case 'planner':
      case 'plannerOrange':
        page.default.layout = page.default.layout || ((page) => <MainPlanner children={page} />)
        break
      default:
        page.default.layout = page.default.layout || ((page) => <MainDefault children={page}/>)
        break
    }

    return page
  },
  setup({el, App, props}) {
    const root = createRoot(el)
    root.render(<App {...props} />)
  },
  progress: {
    color: '#4B5563',
  },
})
