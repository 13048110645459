import React, {useEffect, useState} from 'react'
import {Descriptions, Video} from "@metronic/Components/Securities/Blocks"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"
import {clsx} from "clsx"
import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import {Card, CardBody} from "reactstrap"

const GeneralInformationBlock = ({blockId}) => {
  const {tabData} = useSecuritiesContext()
  const [isVideo, setIsVideo] = useState(false)

  useEffect(() => {
    if (tabData && tabData.length && blockId) {
      const block = tabData.find(block => block.uuid === blockId)
      setIsVideo(!!block.video_url)
    }
  }, [tabData, blockId])

  return (
    <>
      <Card>
        <CardBody>
          <div className={clsx('d-flex', VITE_APP_VARIANT === 'light' ? (isVideo ? 'flex-column flex-xxl-row' : '') :
            isVideo ? 'flex-column flex-lg-row' : ''
          )}>
            <Descriptions blockId={blockId}/>
            {isVideo ?
              <Video blockId={blockId}/> : null
            }
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default GeneralInformationBlock
