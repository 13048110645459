import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {ProfileContent} from '@metronic/Components'
import {TabProvider} from "@metronic/Components/Profile/TabContext/TabContext.jsx"
import {Head} from "@inertiajs/react"

const Profile = (props) => {
  return (
    <>
      <Head title={'Profile | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>My Profile</PageTitle>
      <TabProvider>
        <ProfileContent />
      </TabProvider>
    </>
  )
}

export default Profile
