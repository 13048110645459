import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {BankDetailsContent} from '@metronic/Components'
import {Head} from "@inertiajs/react"

const BankDetails = () => {
  return (
    <>
      <Head title={'Deposit Bank Details | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>Bank Transfer</PageTitle>
      <BankDetailsContent />
    </>
  )
}

export default BankDetails
