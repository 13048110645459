import React from 'react'
import {Head} from "@inertiajs/react"
import UnsubscribeContent from "@metronic/Components/UnsubscribeContent.jsx"

const Unsubscribe = () => {
  return (
    <>
      <Head title={'Unsubscribe | ' + import.meta.env.VITE_APP_NAME} />
      <UnsubscribeContent />
    </>
  )
}

export default Unsubscribe
