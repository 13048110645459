import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {DepositCreditCardContent} from "@metronic/Components"
import {Head} from "@inertiajs/react"

const DepositCreditCard = () => {
  return (
    <>
      <Head title={'Deposit Credit Card | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>Deposit Credit Card</PageTitle>
      <DepositCreditCardContent />
    </>
  )
}

export default DepositCreditCard
