import React, { useState } from "react"
import { ModalContext } from "@metronic/Components/Modal"
import {BaseModal} from "@metronic/Components/Modal"

export const ModalProvider = ({ children }) => {
  const [modalOpened, setModalOpened] = useState(false)
  const [modalContent, setModalContent] = useState(null)

  const openModal = (modalConfig) => {
    setModalContent(modalConfig)
    setModalOpened(true)
  }

  const closeModal = () => {
    setModalOpened(false)
    setModalContent({})
  }

  const valueModalProvider = {
    openModal,
    closeModal
  }

  return (
    <ModalContext.Provider value={valueModalProvider}>
      {modalOpened && <BaseModal {...modalContent} />}
      {children}
    </ModalContext.Provider>
  )
}
