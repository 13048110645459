
export default {
  // Sign Up
  "Create Your Account": "Create Your Account",
  "First Name": "First Name",
  "Last Name": "Last Name",
  "Password": "Password",
  "Confirm password": "Confirm password",
  "Create an account": "Create an account",
  "Already have an account": "Already have an account",
  "Sign In": "Sign In",
  "Easy crypto trading for everyone": "Easy crypto trading for everyone",
  "Successful traders use": "Successful traders use",
  "as a simple, easy, and secure platform to buy and sell Bitcoin, Ethereum and dozens of other digital assets instantly": " " +
    "as a simple, easy, and secure platform to buy and sell Bitcoin, Ethereum and dozens of other digital assets instantly",
  "Join us today! Sign up for a new account and explore a realm of exciting opportunities": "Join us today! " +
    "Sign up for a new account and explore a realm of exciting opportunities",
  "Enter first name": "Enter first name",
  "Enter last name": "Enter last name",
  "Enter email address": "Enter email address",
  "Enter password": "Enter password",
  "Name is too short": "Name is too short",
  "Name is too long": "Name is too long",
  "This field is required": "This field is required",
  "Password must be at least 8 characters": "Password must be at least 8 characters",
  "At least lowercase letter": "At least lowercase letter",
  "At least uppercase letter": "At least uppercase letter",
  "At least one number": "At least one number",
  "Second name is too short": "Second name is too short",
  "Second name is too long": "Second name is too long",
  "Passwords do not match": "Passwords do not match",
  "The email must be a valid email address.": "The email must be a valid email address",
  "CAPTCHA field is required.": "CAPTCHA field is required",
  "Invalid CAPTCHA. You need to prove you are human.": "Invalid CAPTCHA. You need to prove you are human.",
  "Sign Up": "Sign Up",
  "Your account successfully registered, check your email for further instructions.": "Your account successfully " +
    "registered, check your email for further instructions.",
  "Go to Sign In": "Go to Sign In",

  // Sign In
  "Welcome Back!": "Welcome Back!",
  "Sign in to": "Sign in to",
  "Register now": "Register now",
  "Email": "Email",
  "Enter email": "Enter email",
  "Enter Password": "Enter Password",
  "Remember me": "Remember me",
  "Don't have an account ?": "Don't have an account ?",
  "Please Enter Your Email": "Please Enter Your Email",
  "Please Enter Your Password": "Please Enter Your Password",

  //Pending page
  "Thank you for registration!": "Thank you for registration!",
  "Soon your account will be activated": "Soon your account will be activated",
  "Logout": "Logout",

  //2fa
  "Lock Screen": "Lock Screen",
  "Enter your 2FA code to unlock the screen!": "Enter your 2FA code to unlock the screen!",
  "2FA code": "2FA code",
  "Enter code": "Enter code",
  "Unlock": "Unlock",
  "Invalid credentials": "Invalid credentials"
}
