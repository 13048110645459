import React, {useState} from 'react'
import {Button, Card, Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap"
import {KTIcon} from "@metronic/_metronic/helpers/index"
import {Link} from "@inertiajs/react"

const SecurityDetailsDropdown = (props) => {
  const {
    selectedSecurities,
    openBuySecuritiesModal,
    consultEmail,
    isOwnDetails,
    openSellSecuritiesModal,
    acquiredSecurity
  } = props
  const [isDropdown, setIsDropdown] = useState(false)

  const toggleDropdown = () => {
    setIsDropdown(!isDropdown)
  }

  return (
    <Dropdown
      isOpen={isDropdown}
      toggle={toggleDropdown}
    >
      <DropdownToggle
        type="button"
        tag="button"
        className="btn btn-active-light-primary p-2"
      >
        <KTIcon iconName='category' className="fs-1 p-0"/>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg py-0">
        <DropdownItem>
          <Link href='/support/create' className="d-flex">
            <div className="d-flex align-items-center">
              <KTIcon iconName='support-24' className="fs-1 p-0 me-1 text-primary"/>
              <div className="d-flex flex-column">
                <span className="fs-7">Having issues?</span>
                <span className="text-muted fs-8">Contact support</span>
              </div>
            </div>
          </Link>
        </DropdownItem>
        {consultEmail ?
          <DropdownItem>
            <a href={`mailto:${consultEmail}`} target="_blank" className="d-flex">
              <div className="d-flex align-items-center">
                <KTIcon iconName='message-question' className="fs-1 p-0 me-1 text-primary"/>
                <div className="d-flex flex-column">
                  <span className="fs-7">Having question?</span>
                  <span className="text-muted fs-8">Contact advisor</span>
                </div>
              </div>
            </a>
          </DropdownItem> : null
        }
        <DropdownItem>
          <Link href='/wallet' className="d-flex">
            <div className="d-flex align-items-center">
              <KTIcon iconName='wallet' className="fs-1 p-0 me-1 text-info"/>
              <div className="d-flex flex-column">
                <span className="fs-7 text-info">View balance</span>
                <span className="text-muted fs-8">Go to 'My Wallet'</span>
              </div>
            </div>
          </Link>
        </DropdownItem>
        <DropdownItem>
          <Link href='#' onClick={(e) => {
            e.preventDefault()
            openBuySecuritiesModal(selectedSecurities)
          }} className="d-flex">
            <div className="d-flex align-items-center">
              <KTIcon iconName='basket' className="fs-1 p-0 me-1 text-success"/>
              <div className="d-flex flex-column">
                <span className="fs-7 text-success">Purchase</span>
                <span className="text-muted fs-8">Purchase item</span>
              </div>
            </div>
          </Link>
        </DropdownItem>
        {isOwnDetails ?
          <DropdownItem>
            <Link href='#' onClick={(e) => {
              e.preventDefault()
              openSellSecuritiesModal(acquiredSecurity)
            }} className="d-flex">
              <div className="d-flex align-items-center">
                <KTIcon iconName='basket' className="fs-1 p-0 me-1 text-primary"/>
                <div className="d-flex flex-column">
                  <span className="fs-7 text-primary">Sell</span>
                  <span className="text-muted fs-8">Sell item</span>
                </div>
              </div>
            </Link>
          </DropdownItem> : null
        }

      </DropdownMenu>
    </Dropdown>
  )
}

export default SecurityDetailsDropdown
