import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {WithdrawalCryptoContent} from "@metronic/Components"
import {Head} from "@inertiajs/react";

const WithdrawalCrypto = () => {

  return (
    <>
      <Head title={'Withdrawal Crypto | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>Withdrawal Crypto</PageTitle>
      <WithdrawalCryptoContent />
    </>
  )
}

export default WithdrawalCrypto
