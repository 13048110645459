import React, {useContext} from "react"
import {Modal, ModalBody, ModalHeader} from "reactstrap"
import {ModalContext} from "@metronic/Components/Modal"
import {KTIcon} from "@metronic/_metronic/helpers"
import {clsx} from "clsx"

const BaseModal = (props) => {
  const { closeModal } = useContext(ModalContext)

  const toggleModalHandler = () => {
    closeModal()
  }

  const headerTag = () => {
    return (
      props.title &&
        <div className="d-flex justify-content-between align-items-center mt-2 ps-6 pe-3 pb-6 pt-3">
          <h4 className="modal-title">{props.title}</h4>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeModal}>
            <i className='bi bi-x fs-2x'></i>
          </div>
        </div>
    )
  }

  return (
    <Modal
      className="d-print-none"
      centered={props.centered}
      isOpen={true}
      toggle={toggleModalHandler}
      size={props.size || 'md'}
      fullscreen={props.fullscreen || 'sm'}
    >
      <ModalHeader wrapTag={headerTag} />
      <ModalBody className={clsx('', props.title && 'pt-0')}>
        {props.content}
      </ModalBody>
    </Modal>
  )
}

export default BaseModal
