import React, {useEffect, useState} from 'react'
import {Card, CardBody} from "reactstrap"
import {clsx} from "clsx"
import _, {capitalize} from "lodash"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"
import {allListLink, blockCardTitle} from "@metronic/Components/Securities/Components.jsx"
import {CalculationBlock} from "@metronic/Components/Securities/Blocks"
import {checkImageValidity, formatSecuritiesPrice} from "@metronic/helpers/utils.jsx"
import {SecurityType} from "@metronic/Enums/Securities/SecurityType.js"
import moment from "moment-timezone"

const cardsClasses = 'p-3 d-flex flex-column card cursor-pointer w-250px w-md-350px p-lg-3 mx-lg-2 w-lg-250px ' +
  'p-xl-5 mx-xl-0 w-xl-325px mx-xxl-1 w-xxl-375px'

const PopularList = ({blockId}) => {
  const [isMouseOnCard, setIsMouseOnCard] = useState(0)
  const {activeSecurityId, setActiveSecurityId, setActiveSecurity, tabData, securitiesList, allListTab} = useSecuritiesContext()
  const [popularList, setPopularList] = useState([])
  const [imageValidity, setImageValidity] = useState({})
  const [selectedAnnualGrowth, setSelectedAnnualGrowth] = useState(0)

  const selectSecurityHandler = (item, idx) => {
    setActiveSecurityId(idx)
    setActiveSecurity(item)
  }

  useEffect(() => {
    if (securitiesList.length) {
      setPopularList(securitiesList.filter(i => i?.fields?.popular?.value))
    }
  }, [securitiesList])

  useEffect(() => {
    const validateImages = async () => {
      const results = {}

      for (const item of popularList) {
        const meta = item.fields
        if (meta['logo']?.value) {
          results[item.id] = await checkImageValidity(meta['logo']?.value)
        } else {
          results[item.id] = false
        }
      }
      setImageValidity(results)
    }

    validateImages()
  }, [popularList])

  return (
    <Card>
      <CardBody>
        {blockCardTitle(tabData.find(i => i.uuid === blockId)?.title)}

        <div
          className="scroll-x overflow-auto d-flex flex-row align-items-stretch mh-100 gap-5 gap-xl-4 gap-xxl-5 pb-5 pt-3 px-3">
          {(popularList && popularList.length) ?
            popularList.map((item, idx) => {
              const id = idx + 1
              const fields = item.fields
              const apiData = item.api_data
              const isValid = imageValidity[item.id]
              const type = item.type
              let annualGrowth

              if (type === SecurityType.BOND) {
                annualGrowth = fields['coupon_rate'] ? fields['coupon_rate'].value : '-'
              } else if (type === SecurityType.INDEX && !_.isEmpty(apiData)) {
                annualGrowth = apiData?.index_price?.change_1y_pct || 0.00
              } else if (type === SecurityType.STOCK && !_.isEmpty(apiData)) {
                annualGrowth = apiData?.stock_price?.change_1y_pct || 0.00
              } else {
                annualGrowth = '-'
              }

              return (
                <div className="mh-100" key={idx}>
                  <Card
                    className={clsx('h-100', cardsClasses,
                      isMouseOnCard === id && 'bg-light-primary shadow-sm',
                      activeSecurityId === id && 'shadow-sm bg-light-success'
                    )}
                    onClick={() => {
                      selectSecurityHandler(item, id)
                      setSelectedAnnualGrowth(annualGrowth)
                    }}
                    onMouseEnter={() => setIsMouseOnCard(id)}
                    onMouseLeave={() => setIsMouseOnCard(0)}
                  >
                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column">
                        <span className="text-muted fs-7 pb-2 fw-bold">{capitalize(item.type)}</span>
                        <h4 className="text-gray-700">{item.name}</h4>
                      </div>
                      <div>
                        {fields['logo']?.value && isValid !== undefined ?
                          (
                            isValid ?
                              (
                                <div className="symbol symbol-40px shadow p-1">
                                  <img src={fields['logo'].value} alt="image"/>
                                </div>
                              ) :
                              <div className="symbol symbol-40px border p-1">
                                <div className="symbol-label fs-2 fw-semibold text-primary">
                                  {item.name.slice(0, 1)}
                                </div>
                              </div>
                          ) :
                          <div className="symbol symbol-40px border p-1">
                            <div className="symbol-label fs-2 fw-semibold text-primary">
                              {item.name.slice(0, 1)}
                            </div>
                          </div>
                        }
                      </div>
                    </div>

                    <div className={clsx('separator mt-4 mb-3 w-75',
                      isMouseOnCard === id && 'border-primary opacity-25',
                      activeSecurityId === id && 'border-primary opacity-25'
                    )}></div>

                    <div className="d-flex flex-column mb-4 h-100 align-items-end">
                    <span className="text-muted text-start w-100 fs-7 fw-bold pb-1">
                      {type === SecurityType.BOND ? 'Maturity date' : ''}
                    </span>
                    <span className="text-gray-700 fs-6 fw-bold w-100">
                      {type === SecurityType.BOND ?
                        <span>{fields['maturity_date'] ? fields['maturity_date'].value : '-'}</span> : ''
                      }
                      </span>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="d-flex flex-column">
                      <span className="text-muted fs-7 fw-bold pb-1">
                        {type === SecurityType.BOND ? 'Coupon rate' : 'Annual Growth'}
                      </span>
                        <span className="text-gray-700 fs-6 fs-lg-7 fs-xl-6 fw-bold">
                          {type !== SecurityType.BOND ?
                          <span
                            className={`p-0 pe-1 fs-6 badge badge-${Number(annualGrowth) > 0 ? 'light-success' :
                              (Number(annualGrowth) < 0 ? 'light-danger' : 'light ps-1')}`}
                          >
                            <i className={`bi bi-arrow-${Number(annualGrowth) > 0 ? 'up' :
                              (Number(annualGrowth) < 0 ? 'down' : '')}-short fs-2 p-0 fw-bolder text-${Number(annualGrowth) > 0 ? 'success' :
                              (Number(annualGrowth) < 0 ? 'danger' : 'muted')}`}
                            ></i>
                            <span>{Math.abs(annualGrowth) || '-'}%</span>
                          </span>
                            :
                            <span>{annualGrowth || '-'}%</span>
                          }
                        </span>
                      </div>

                      <div className="d-flex flex-column">
                        <span className="text-muted fs-7 fw-bold pb-1">Price</span>
                        <span className="text-gray-700 fs-6 fs-lg-7 fs-xl-6 fw-bold">
                        {formatSecuritiesPrice(fields['payout_asset']?.payout_asset_code, item?.price)}
                      </span>
                      </div>
                    </div>
                  </Card>
                </div>
              )
            }) :
            <h3 className="text-gray-600">The list is currently unavailable</h3>
          }
        </div>

        {allListTab ? allListLink() : null}

        {((tabData && tabData?.find(i => i.uuid === blockId)?.is_calculator_visible) && (popularList && popularList.length)) ?
          <CalculationBlock annualGrowth={selectedAnnualGrowth} blockId={blockId}/> : null
        }

      </CardBody>
    </Card>
  )
}

export default PopularList
