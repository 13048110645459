
export default {
  "Notifications": "Notifications",
  "Mark all as read": "Mark all as read",
  "View All Notifications": "View All Notifications",
  "Title": "Title",
  "Subtitle": "Subtitle",
  "Created At": "Created At",
  "Mark as unread": "Mark as unread",
  "Mark as read": "Mark as read",
  "Read": "Read",
  "Unread": "Unread",
  "All notifications is read!": "All notifications is read!",
  "You got invoice {tr_id}, status is \"{tr_status}\"": "You got invoice {tr_id}, status is \"{tr_status}\"",
  "Transaction #{tr_id} was updated": "Transaction #{tr_id} was updated",
  "New status is \"{tr_status}\"": "New status is \"{tr_status}\"",
  "Transaction #{tr_id} status is \"{tr_status}\"": "Transaction #{tr_id} status is \"{tr_status}\"",
  "Sold \"{sold_asset}\" and Bought \"{buy_asset}\"": "Sold \"{sold_asset}\" and Bought \"{buy_asset}\"",
  "Verification status!": "Verification status!",
  "Now your verification status is \"{tr_status}\"": "Now your verification status is \"{tr_status}\"",
  "Ticket C-{tr_id} was answered": "Ticket C-{tr_id} was answered",
  "Ticket C-{tr_id} was closed": "Ticket C-{tr_id} was closed",
  "Ticket C-{tr_id} was opened": "Ticket C-{tr_id} was opened",
  "Your document was approved!": "Your document was approved!",
  "Document type: \"{doc_type}\"": "Document type: \"{doc_type}\"",
  "Your document was declined!": "Your document was declined!",
  "Invoice {tr_id} status updated": "Invoice {tr_id} status updated",
  "Transaction #{tr_id} got new comment": "Transaction #{tr_id} got new comment",
  "You created #{tr_id} transaction to pay invoice {invoice_id}, status: \"{tr_status}\"": "You created #{tr_id} " +
    "transaction to pay invoice {invoice_id}, status: \"{tr_status}\"",
  "You successfully paid invoice {invoice_id}, transaction id: #{tr_id}": "You successfully paid " +
    "invoice {invoice_id}, transaction id: #{tr_id}"
}
