import React, {useState} from 'react'
import {Button, Card, CardBody, CardHeader, Input} from "reactstrap"
import {clsx} from "clsx"
import {useForm} from "@inertiajs/react";

const CreateNewTicketBody = () => {
  const [message, setMessage] = useState('')
  const [isMessageInvalid, setIsMessageInvalid] = useState(false)
  const [subject, setSubject] = useState('')
  const [isSubjectInvalid, setIsSubjectInvalid] = useState(false)

  const {setData, post, errors, processing} = useForm({
    subject: '',
    message: '',
  })

  const submitHandler = () => {
    let success = true

    if (!subject) {
      setIsSubjectInvalid(true)
      success = false
    }

    if (!message) {
      setIsMessageInvalid(true)
      success = false
    }

    if (!success) {
      return
    }

    post('/support/create')
  }

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
    }
  }

  return (
    <>
      <Card>
        <CardHeader>
          <h2 className="text-gray-700 w-100 d-flex align-items-center justify-content-center pb-0 mb-0">
            Create a new ticket
          </h2>
        </CardHeader>
        <CardBody className="d-flex flex-column">
          <Input
            type="text"
            className={clsx('form-control mb-3', isSubjectInvalid ? 'is-invalid' : 'border-light-subtle')}
            placeholder='Type your subject'
            value={subject}
            onChange={(e) => {
              setSubject(e.target.value)
              setData('subject', e.target.value)
              setIsSubjectInvalid(false)
            }}
          />

          <textarea
            className={clsx('form-control mb-3', isMessageInvalid ? 'is-invalid' : 'border border-light-subtle')}
            rows={5}
            placeholder='Type your message'
            value={message}
            onChange={(e) => {
              setMessage(e.target.value)
              setData('message', e.target.value)
              setIsMessageInvalid(false)
            }}
            onKeyDown={onEnterPress}
          />
          <div className="w-100 text-end">
            <Button
              className='btn btn-sm btn-light-success px-3 py-1'
              type='button'
              onClick={submitHandler}
            >
              Submit
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  )
}

export default CreateNewTicketBody
