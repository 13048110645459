import React from 'react'
import {TwoFactorContent} from '@metronic/Components'
import {Head} from "@inertiajs/react"

const TwoFactor = () => {

  return (
    <>
      <Head title={'2FA | ' + import.meta.env.VITE_APP_NAME} />
      <TwoFactorContent />
    </>
  )
}

export default TwoFactor
