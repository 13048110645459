import React, {useEffect, useState} from 'react'
import clsx from "clsx"
import {KTIcon} from '@metronic/_metronic/helpers'
import {AsideUserMenuLight} from "@metronic/Components/HeaderDropdowns"
import {Col, Row} from "reactstrap"
import {Link, usePage} from "@inertiajs/react"
import {useThemeMode} from "../../../../partials/layout/theme-mode/ThemeModeProvider"
import {useWindowSize} from "@metronic/helpers/utils.jsx"

const logosUrl = '/storage/brand_logos/'
const logoLightSmUrl = logosUrl + 'logo-light-sm.png'
const logoDarkSmUrl = logosUrl + 'logo-dark-sm.png'
const logoLightUrl = logosUrl + 'logo-light.png'
const logoDarkUrl = logosUrl + 'logo-dark.png'

export function AsideMenuMainLight() {
  const {props: pageProps} = usePage()
  const accountBalance = pageProps.account_balance
  const showSecurities = import.meta.env.VITE_SHOW_SECURITIES
  const showTrade = import.meta.env.VITE_SHOW_TRADE
  const {mode} = useThemeMode()
  const [logoVariant, setLogoVariant] = useState('')
  const {width: windowWidth} = useWindowSize()
  const [securitiesData, setSecuritiesData] = useState([])

  useEffect(() => {
    if (pageProps.securities_pages && pageProps.securities_pages?.length) {
      setSecuritiesData(pageProps.securities_pages?.filter(i => i.visible))
    }
  }, [pageProps])

  const meinMenuItems = [
    {name: 'Dashboard', icon: 'bank', link: 'dashboard'},
    {name: 'Wallet', icon: 'wallet', link: 'wallet'},
    {name: 'Exchange', icon: 'arrow-right-left', link: 'exchange'},
    {name: 'Transactions', icon: 'dollar', link: 'transactions'},
    {name: 'Invoices', icon: 'tag', link: 'invoices.index'},
    {name: 'Support', icon: 'message-question', link: 'support'},
  ]

  const additionalMenuItems = [
    {name: 'Deposit', pages: [
        {subName: 'Credit Card', icon: 'credit-cart', link: 'deposit.credit-card'},
        {subName: 'Crypto', icon: 'bitcoin', link: 'deposit.crypto'},
        {subName: 'Bank Transfer', icon: 'subtitle', link: 'deposit.bank-transfer'},
      ]
    },
    {name: 'Withdrawal', pages: [
        {subName: 'Crypto', icon: 'exit-right-corner', link: 'withdrawal.crypto'},
        {subName: 'Bank Transfer', icon: 'arrow-mix', link: 'withdrawal.bank-transfer'},
      ]
    },
  ]

  if (showTrade === 'true') {
    // const tradeMenu = {
    //   name: 'Trade',
    //   pages: [
    //     {subName: 'P2P', icon: 'update-file', link: 'p2p-orders.p2pOrders'},
    //   ]
    // }

    const p2p = {subName: 'P2P', icon: 'update-file', link: 'p2p-orders.p2pOrders'}

    additionalMenuItems[0].pages.push(p2p)
  }

  if (showSecurities === 'true' && securitiesData && securitiesData.length) {
    const securitiesMenu = {
      name: 'Securities',
      pages: securitiesData.map(item => ({
        subName: item.name,
        icon: 'shield',
        link: 'securities.getPage',
        slug: item.slug,
      }))
    }

    additionalMenuItems.push(securitiesMenu)
  }

  useEffect(() => {
    if (mode === 'dark') {
      if (windowWidth >= 992) {
        setLogoVariant(logoLightUrl)
      } else {
        setLogoVariant(logoLightSmUrl)
      }
    } else {
      if (windowWidth >= 992) {
        setLogoVariant(logoDarkUrl)
      } else {
        setLogoVariant(logoDarkSmUrl)
      }
    }
  }, [mode, windowWidth])

  return (
    <>

      <div className="px-6 py-8 d-flex flex-column flex-column-fluid pb-lg-15">
        {/* BEGIN Top logos */}
        <div className="d-flex justify-content-between align-items-center">

          {/* BEGIN header logo */}
          <div className="w-100">
            <img src={logoVariant || '#'} className="h-50px theme-light-hide" alt=""/>
          </div>
          {/* END header logo */}

          {/* BEGIN User dropdown */}
          <div className='d-flex align-items-center'>
            <AsideUserMenuLight/>
          </div>
          {/* END User dropdown */}
        </div>
        {/* END Top logos */}

        {/* BEGIN Account balance */}
        <div className="d-flex flex-column pt-15">
          <div className="w-100 mb-5">
            <div className="rounded-custom-6 border border-dashed border-gray-300 p-2 text-center">
              <h2 className="text-gray-600">Account Balance</h2>
              <h2 className="text-primary">
                ${accountBalance?.total ? Intl.NumberFormat().format(accountBalance?.total) : '0.00'}
              </h2>
            </div>
          </div>

          <div className="w-100 d-flex">
            <div className="rounded-custom-6 w-50 border border-dashed border-gray-300 p-2 text-center me-2">
              <h2 className="text-gray-600">Available</h2>
              <h2 className="text-success">
                ${accountBalance?.available ? Intl.NumberFormat().format(accountBalance?.available) : '0.00'}
              </h2>
            </div>
            <div className="rounded-custom-6 w-50 border border-dashed border-gray-300 p-2 text-center ms-3">
              <h2 className="text-gray-600">Locked</h2>
              <h2 className="text-danger">
                ${accountBalance?.locked ? Intl.NumberFormat().format(accountBalance?.locked) : '0.00'}
              </h2>
            </div>
          </div>
        </div>
        {/* END Account balance */}

        {/*  BEGIN Menu  */}

        <h1 className="text-gray-700 ms-1 my-8">Menu</h1>

        <Row className="row-cols-3 g-3 mb-5">
          {meinMenuItems.map((item, idx) => (
            <Col key={idx}>
              <Link
                href={route(item.link)}
                as="button"
                type="button"
                className="btn btn-secondary btn-active-light-info d-flex
                  flex-center flex-column border-gray-300 h-85px w-85px h-lg-90px w-lg-90px"
                >
                  <span className="mb-1">
                      <KTIcon iconName={item.icon} className="fs-1"/>
                  </span>
                <span className="fs-8 fs-lg-7 fw-bold">{item.name}</span>
              </Link>
            </Col>
          ))}

        </Row>

        {additionalMenuItems.map((item, idx) => (
          <Row className="row-cols-3 g-3 d-block d-lg-none d-flex flex-row" key={idx}>
            <div className={clsx('separator separator-content w-100 my-5',
              (idx > 0) ? 'pt-2' : ''
            )}>
              <span className="text-gray-500 fw-semibold fs-7 text-nowrap">{item.name}</span>
            </div>
            {item.pages.map((item, idx) => {
              const slug = item.slug

              return (
                <Col key={idx}>
                  <Link
                    href={route(item.link, {slug})}
                    onClick={() => {
                      if (slug) {
                        sessionStorage.setItem('page_data', JSON.stringify({title: item.subName, slug: slug}))
                      }
                    }}
                    as="button"
                    type="button"
                    className="btn btn-secondary btn-active-light-info d-flex
                    flex-center flex-column border-gray-300 h-85px w-85px h-lg-90px w-lg-90px mb-3"
                  >
                    <span className="mb-1"><KTIcon iconName={item.icon} className="fs-1"/></span>
                    <span className="fs-8 fs-lg-7 text-nowrap fw-bold">{item.subName}</span>
                  </Link>
                </Col>
                )
            })}
          </Row>
        ))}

        {/*  END Menu  */}

      </div>
    </>
  )
}
