import Sidebar from "./Sidebar"
import Tables from "./Tables"
import Widgets from "./Widgets"
import Deposit from "./Deposit"
import Withdrawal from "./Withdrawal"
import Invoices from "./Invoices"
import Support from "./Support"
import Auth from "./Auth"
import Profile from "./Profile"
import Notifications from "./Notifications"

export default {
  Sidebar,
  Tables,
  Widgets,
  Deposit,
  Withdrawal,
  Invoices,
  Support,
  Auth,
  Profile,
  Notifications,
}
