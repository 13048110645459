import React, {useEffect, useState} from 'react'
import {ChartsWidget3} from "@metronic/_metronic/partials/widgets/index"
import {detailsDisclaimerText} from "@metronic/Components/Securities/Components.jsx"
import {StockPriceComparisonChart} from "@metronic/Components/Securities/Blocks/Charts"
import _ from 'lodash'

const StatsTab = (props) => {
  const {
    securityData,
    selectedSecurities,
    consultEmail
  } = props
  const [securityTickerData, setSecurityTickerData] = useState([])
  const [tickerRange, setTickerRange] = useState('month')
  const [tickerType, setTickerType] = useState('')

  const setRangeHandler = (range) => {
    setTickerRange(range)
  }

  useEffect(() => {
    if (!_.isEmpty(selectedSecurities) && selectedSecurities.api_data) {
      setSecurityTickerData(selectedSecurities.api_data)

      setTickerType(selectedSecurities?.type)
    } else {
      setSecurityTickerData([])
      setTickerType('')
    }
  }, [selectedSecurities])

  return (
    <>
      {(securityData && securityData?.length) ?
        <ChartsWidget3
          className="mb-15"
          data={securityTickerData}
          tickerType={tickerType}
          selectedSecurities={selectedSecurities}
          setRangeHandler={setRangeHandler}
          tickerRange={tickerRange}
        /> : null
      }

      {!_.isEmpty(securityTickerData?.stock_overview_data) ?
        <StockPriceComparisonChart securityTickerData={securityTickerData} /> : null
      }


      {detailsDisclaimerText(consultEmail)}
    </>
  )
}

export default StatsTab
