import React, {useEffect, useImperativeHandle, useRef, useState} from 'react'
import {
  Row,
  Col, Button,
} from 'reactstrap'
import DataTable from 'react-data-table-component'
import classnames from "classnames"
import {router, usePage} from "@inertiajs/react"
import {useThemeMode} from "@metronic/_metronic/partials/layout/theme-mode/ThemeModeProvider"

const BaseTable = (props) => {
  const {props: pageProps} = usePage()
  const [tableData, setTableData] = useState([])
  const [loading, setLoading] = useState(true)
  const [totalRows, setTotalRows] = useState(0)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)
  const [filters, setFilters] = useState([])
  const loadingFinished = useRef(false)
  const [overlay, setOverlay] = useState(false)
  const {mode} = useThemeMode()

  // Table handlers
  const handlePageChange = page => {
    setPage(page)
  }

  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage)
    setPage(page)
  }

  // Filters
  const filtersHandler = (filtersData) => {
    setFilters(filtersData)
  }

  const handleSelect = ({selectedRows}) => {
    props.selectedRowsHandler(selectedRows)
  }

  const actionsMemo = React.useMemo(()=> (
    <>
      {/* Refresh button */}
      <Button
        color="text-muted"
        outline
        className="hstack justify-content-center text-muted align-items-center"
        onClick={() => {
          if (loadingFinished.current) {
            getData(true)
          }
        }}
      >
        <i className="bi bi-bootstrap-reboot fs-2 refresh"></i>
      </Button>
    </>
  ), [])

  const getData = (withOverlay) => {
    if (withOverlay) setOverlay(true)

    loadingFinished.current = false

    router.reload({only: ['table_data', 'flash']})

    loadingFinished.current = true

    // router.get('/invoices', [], {
    //   preserveScroll: true,
    //   onSuccess: (page, data) => {
    //     console.log('page, data', page, data);
    //   },
    //   onError: (errors) => {},
    // })

    // api.get(props.url, {page: page, per_page: perPage, ...filters}).then(response => {
    //   setTableData(response.data)
    //   setTotalRows(response.total)
    //   setLoading(false)
    // }).finally(() => {
    //   setLoading(false)
    //   setOverlay(false)
    //   loadingFinished.current = true
    // })

    setTableData([])
  }

  useImperativeHandle(props.methodsRef, () => ({
    updateTable: () => {
      getData()
    }
  }))

  // Get data
  useEffect(() => {
    setLoading(true)
    getData()
  }, [page, perPage, filters])

  useEffect(() => {
    if (pageProps.table_data.data) {
      setTableData(pageProps.table_data.data)
      setLoading(false)
    }
  }, [pageProps])

  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col className="col-12 dropdown_area">
          <DataTable
            className={classnames( {'rdt_Table-overlay': overlay})}
            theme={mode === 'dark' ? 'dark' : 'default'}
            striped={true}
            dense={true}
            title={props.title}
            columns={props.columns}
            data={tableData}
            selectableRows={props.selectableRows}
            onSelectedRowsChange={handleSelect}
            contextActions={props.actions || []}
            progressPending={loading}
            pagination
            paginationServer
            paginationRowsPerPageOptions={[25, 50, 100]}
            paginationPerPage={perPage}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            paginationComponentOptions={{
              rowsPerPageText: 'Rows per page:'
            }}
            progressComponent={<h1 className="py-4">Loading...</h1>}
            noDataComponent={<div className="text-center py-4">There are no records to display</div>}
            actions={actionsMemo}
          />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default BaseTable
