import React, {FC} from 'react'
import clsx from 'clsx'
import {useLayout} from '../../../core'
import {AsideMenuLight} from './AsideMenuLight'

const AsideLight: FC = () => {
  const {config, classes} = useLayout()
  const {aside} = config

  return (
    <div
      id='kt_aside'
      style={{position: 'fixed', top: 0, left: 0, bottom: 0,}}
      className={clsx('aside d-print-none overflow-lg-hidden w-300 w-lg-350px m-0 mt-lg-9 ms-lg-9 mb-lg-9 card',
        classes.aside.join(' '), {'d-none': !aside.display})}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >
      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid w-300 w-lg-350px'>
        <AsideMenuLight asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}
    </div>
  )
}

export {AsideLight}
