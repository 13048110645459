import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core/index"
import PeerToPeerContent from "@metronic/Components/P2P/PeerToPeerContent.jsx"

const PeerToPeer = () => {
  return (
    <>
      <PageTitle breadcrumbs={[]}>P2P</PageTitle>
      <PeerToPeerContent />
    </>
  )
}

export default PeerToPeer
