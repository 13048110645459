import {CreateRequestCard} from "@metronic/Components/P2P/Components/index.jsx"
import React from "react"
import {Head} from "@inertiajs/react";
import {PageTitle} from "@metronic/_metronic/layout/core"

const P2PCreate = () => {
  return (
    <>
      <Head title={'Create P2P Order | ' + import.meta.env.VITE_APP_NAME}/>
      <PageTitle breadcrumbs={[]}>Create P2P Order</PageTitle>
      <CreateRequestCard/>
    </>
  )
}

export default P2PCreate
