import React from "react";
import {useLayout} from '../../core'
import {Toolbar1Default} from './Toolbar1Default'
import {Toolbar1Light} from './Toolbar1Light'
import {Toolbar1Planner} from './Toolbar1Planner'
import {VITE_APP_VARIANT} from '@metronic/helpers/utils'
import {AppVariant} from "../../../../Enums/AppVariants/AppVariant"

const Toolbar = ({isWallet, activeTab}) => {
  const {config} = useLayout()

  const toolbarVariations = () => {
    if (VITE_APP_VARIANT === AppVariant.DEFAULT) {
      return <Toolbar1Default isWallet={isWallet} activeTab={activeTab} />
    } else if (VITE_APP_VARIANT === AppVariant.LIGHT) {
      return <Toolbar1Light isWallet={isWallet} activeTab={activeTab} />
    } else if (VITE_APP_VARIANT === AppVariant.PLANNER || VITE_APP_VARIANT === AppVariant.PLANNER_ORANGE) {
      return <Toolbar1Planner isWallet={isWallet} activeTab={activeTab} />
    } else {
      return <Toolbar1Default isWallet={isWallet} activeTab={activeTab} />
    }
  }

  switch (config.toolbar.layout) {
    case 'toolbar1':
      return toolbarVariations()

    default:
      return <Toolbar1Default isWallet={isWallet} activeTab={activeTab} />
  }
}

export {Toolbar}
