import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {SecuritiesContent} from "@metronic/Components"
import {SecuritiesProvider} from "@metronic/Components/Securities/Context/SecuritiesContext.jsx"
import {Head} from "@inertiajs/react"

const Bonds = (props) => {
  const showSecurities = import.meta.env.VITE_SHOW_SECURITIES
  const pageTitle = JSON.parse(sessionStorage.getItem('page_data'))?.title || ''

  return (
    (showSecurities === 'true') ?
    <>
      <SecuritiesProvider {...props}>
        <Head title={'Securities | ' + pageTitle + ' | ' + import.meta.env.VITE_APP_NAME} />
        <PageTitle breadcrumbs={[]}>
          {pageTitle}
        </PageTitle>
        <SecuritiesContent />
      </SecuritiesProvider>
    </> :
    <>
    </>
  )
}

export default Bonds
