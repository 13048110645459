const AppVariant = Object.freeze({
  DEFAULT: 'default',
  LIGHT: 'light',
  PLANNER: 'planner',
  PLANNER_ORANGE: 'plannerOrange'
})

export {
  AppVariant
}
