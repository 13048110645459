import React, {useContext, useRef} from 'react'
import {Button, Col, Input, Row} from "reactstrap"
import {assetFullName, copyToClipboard, cryptoIconsMapper, isTwoPrecision} from "@metronic/helpers/utils.jsx"
import {KTIcon} from "@metronic/_metronic/helpers/index"
import {useForm, usePage} from "@inertiajs/react"
import {ModalContext} from "@metronic/Components/Modal/ModalsContext/ModalContext.jsx"

const printInvoice = () => {
  window.print()
}

const SaleConfirmationCard = ({order, status}) => {
  const userInfo = usePage().props?.auth?.user
  const unloadedFile = useRef('')
  const {closeModal} = useContext(ModalContext)

  const {data, setData, post, reset} = useForm({
    document: '',
  })

  const downloadDocumentHandler = (e) => {
    e.preventDefault()

    post(`p2p-orders/verification/${order.id}`, {
      preserveScroll: true,
    })

    closeModal()
  }

  const template = (status) => {
    const pendingTemplate = (
      <div className="d-flex flex-column flex-lg-row d-print-none justify-content-between">

        <div className="mb-5 mb-lg-0 w-100 pe-0 pe-lg-5">
          <Input
            type="file"
            name="document"
            className="w-100 me-10"
            onChange={(e) => {
              unloadedFile.current = e.target.files[0]
              setData('document', e.target.files[0])
            }}
          />
        </div>

        <div className="d-flex justify-content-between">
          <Button
            type="button"
            className="btn-sm btn-lg btn-light-success fw-bold min-w-150px"
            onClick={(e) => downloadDocumentHandler(e)}
          >
            Done
          </Button>

          <Button
            type="button"
            onClick={printInvoice}
            color="primary"
            className="btn-sm btn-lg d-flex align-items-center btn-sm btn-light-primary w-70px w-sm-80px px-5 fw-bold ms-3"
          >
            Print <KTIcon iconName='printer' className='fs-2 ms-2'/>
          </Button>
        </div>

      </div>
    )

    const waitingForPaymentTemplate = (
      <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
        <div>
          Your document on verification
        </div>

        <div className="d-flex mt-5 mt-sm-0">
          <Button
            type="button"
            onClick={printInvoice}
            color="primary"
            className="btn d-flex align-items-center btn-sm btn-light-primary w-80px px-2 px-sm-5 fw-bold ms-3"
          >
            Print <KTIcon iconName='printer' className='fs-2 ms-2'/>
          </Button>
        </div>
      </div>
    )

    if (status === 20) {
      return pendingTemplate
    } else if (status === 30) {
      return waitingForPaymentTemplate
    } else {
      return (
        <p>Error!</p>
      )
    }
  }

  return (
    <>
      <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
        <Col className="col-12">
          <div className="w-100 text-end">
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeModal}>
              <i className='bi bi-x fs-2x'></i>
            </div>
          </div>
          <Row className="pb-5">
            <h1 className="w-100 text-center mb-10">Order confirmation</h1>

            <div className="d-flex flex-column flex-md-row d-print-none">
              <div
                className="fw-bold fs-4 w-100 p-2 bg-light-info text-center text-gray-700 rounded-2 cursor-pointer"
                onClick={() => copyToClipboard('text')}
              >
                {userInfo.profile.first_name}
                <KTIcon iconName='copy' className='fs-3 ps-2 d-print-none'/>
              </div>
              <div className="px-3 d-none d-md-block"></div>
                <div
                  className="fw-bold fs-4 w-100 p-2 bg-light-info text-center text-gray-700 rounded-2 cursor-pointer mt-3 mt-md-0"
                  onClick={() => copyToClipboard('text')}
                >
                  {userInfo.profile.last_name}
                  <KTIcon iconName='copy' className='fs-3 ps-2 d-print-none'/>
                </div>
              </div>

              <div className="d-flex flex-column flex-lg-row align-items-center mt-3">
                <div
                  className="d-flex flex-column align-items-center justify-content-center
                  bg-light-warning p-3 rounded-2 w-100 px-5"
                >
                  <div className="d-flex align-items-center">
                    <span
                      className="fs-5 fw-bold me-2 bg-light-info p-2 text-gray-700 cursor-pointer"
                      onClick={() => copyToClipboard('text')}
                    >
                      {isTwoPrecision(order.sell_asset.currency.code, order.sell_price)}
                      <KTIcon iconName='copy' className='fs-3 ps-2 d-print-none'/>
                    </span>
                    <img
                      src={cryptoIconsMapper[order.sell_asset.currency.code]}
                      alt={order.sell_asset.currency.code}
                      className="w-25px me-2"
                    />
                    <span className="fs-5 fw-bold">{assetFullName[order.sell_asset.currency.code]}</span>
                    <i className="bi bi-arrow-up-right-square-fill text-danger fw-bold fs-4 ms-3"></i>
                  </div>
                </div>

                <div className="d-flex align-items-center px-9">
                  <i className="bi bi-arrow-right fs-1 fw-bold text-success d-none d-lg-block"></i>
                  <i className="bi bi-arrow-down fs-1 fw-bold text-success d-block d-lg-none py-3"></i>
                </div>

                  <div
                    className="d-flex flex-column align-items-center justify-content-center
                      bg-light-success p-3 rounded-2 w-100 px-5"
                  >
                    <div className="d-flex align-items-center">
                      <span
                        className="fs-5 fw-bold me-2 bg-light-info p-2 text-gray-700 cursor-pointer"
                        onClick={() => copyToClipboard('text')}
                      >
                        {isTwoPrecision(order.sell_asset.currency.code, order.buy_price)}
                        <KTIcon iconName='copy' className='fs-3 ps-2 d-print-none'/>
                      </span>
                      <img
                        src={cryptoIconsMapper[order.buy_asset.currency.code]}
                        alt={order.buy_asset.currency.code}
                        className="w-25px me-2"
                      />
                      <span className="fs-5 fw-bold">{assetFullName[order.buy_asset.currency.code]}</span>
                      <i className="bi bi-arrow-down-right-square-fill text-success fw-bold fs-4 ms-3"></i>
                    </div>
                  </div>
                </div>
              </Row>

            <Row>
              <div className="separator mt-10 mb-5 border-primary separator-content text-nowrap text-primary fw-bold">
                Bank information
              </div>
            </Row>

            <Row>
              {(order && order?.meta?.order_details) ?
                Object.entries(order?.meta?.order_details).map(([key, val], idx) => {
                  return (
                    <Col className="col-12 col-lg-6 mt-3" key={idx}>
                      <span className="label fw-bold mb-0 fs-5 d-print-none">{key}</span>
                      <span className="label fw-bold mb-0 fs-5 d-none d-print-block">{val}</span>
                      <div
                        className="fw-bold fs-4 w-100 p-2 bg-light-info
                        text-center text-gray-700 rounded-2 cursor-pointer d-flex justify-content-between"
                        onClick={() => copyToClipboard(val)}
                      >
                        <span className="text-truncate">{val}</span>
                        <KTIcon iconName='copy' className='fs-3 ps-2 d-print-none'/>
                      </div>
                    </Col>
                  )
                }) : null}
            </Row>

            <div className="separator my-10 border-primary d-print-none"></div>

            {template(status)}
        </Col>
      </Row>
    </>
  )
}

export default SaleConfirmationCard
