import React, {useEffect, useState} from 'react'
import {detailsDisclaimerText} from "@metronic/Components/Securities/Components.jsx"
import {clsx} from "clsx"
import {AnalystRatingChart} from "@metronic/Components/Securities/Blocks/Charts"
import {formatSecuritiesPrice} from "@metronic/helpers/utils.jsx"
import _, {capitalize} from "lodash"
import {SecurityType} from "@metronic/Enums/Securities/SecurityType.js"

const keysList = [
  'Exchange',
  'Sector',
  'Industry',
  'Analyst Target Price',
  '50 Day Moving Average',
  '52 Week High',
  '52 Week Low',
  'PE Ratio',
  'Forward PE',
  'EPS',
  'Dividend Per Share',
  'Ex Dividend Date',
  'Dividend Date',
  'Dividend Yield'
]

const indexKeyList = [
  'change',
  'change_percent',
  'close',
  'high',
  'low',
  'open',
  'previous_close',
]

const assetValuesKeys = ['dividends', 'par_value', 'current_price', 'total_investments', 'average_price', 'cpv']

const OverviewTab = (props) => {
  const {
    securityData,
    selectedSecurities,
    consultEmail,
    selectedSaap,
    isOwnSecurity,
    activeTableTab
  } = props
  const [priceSummaryData, setPriceSummaryData] = useState('')
  const [securityTickerData, setSecurityTickerData] = useState([])
  const [overviewData, setOverviewData] = useState(null)
  const [keyDataList, setKeyDataList] = useState([])
  const [annualRatesPct, setAnnualRatesPct] = useState('')
  const [annualRates, setAnnualRates] = useState('')

  useEffect(() => {
    if (!_.isEmpty(selectedSecurities) && selectedSecurities.api_data) {
      setSecurityTickerData(selectedSecurities.api_data)
    } else {
      setSecurityTickerData([])
    }
  }, [selectedSecurities])

  useEffect(() => {
    if (selectedSecurities?.type === SecurityType.STOCK && securityTickerData && securityTickerData?.stock_overview_data) {
      setOverviewData(securityTickerData.stock_overview_data.data)
    } else if (selectedSecurities?.type === SecurityType.INDEX && !_.isEmpty(selectedSecurities?.data) && !_.isEmpty(selectedSecurities?.data?.session)) {
      setOverviewData(selectedSecurities?.data?.session)
    } else {
      setOverviewData([])
    }
  }, [securityTickerData, selectedSecurities, activeTableTab])

  useEffect(() => {
    if (!_.isEmpty(selectedSecurities) && selectedSecurities.api_data) {
      setSecurityTickerData(selectedSecurities.api_data)
    } else {
      setSecurityTickerData([])
    }
  }, [selectedSecurities])

  useEffect(() => {
    if (overviewData && selectedSecurities?.type !== SecurityType.BOND) {
      let data = []
      const dataList = selectedSecurities?.type === SecurityType.STOCK ? keysList : indexKeyList

      for (const key in overviewData) {
        if (dataList.includes(key)) {

          data = [...data,
            {
              name: key.replace('_', ' '),
              value: overviewData[key]
            }
          ]
        }
      }

      setKeyDataList(data)
    } else {
      setKeyDataList(null)
    }
  }, [overviewData, selectedSecurities])

  useEffect(() => {
    if (!_.isEmpty(securityTickerData)) {
      let dataObject = null
      let data = {}

      if (!_.isEmpty(securityTickerData)) {
        let type = selectedSecurities?.type
        dataObject = selectedSecurities?.data

        if (dataObject && type === SecurityType.STOCK) {
          if (dataObject && dataObject.market_status === 'early_trading') {
            data = {
              hpUsd: dataObject?.session?.early_trading_change,
              hpPct: dataObject?.session?.early_trading_change_percent,
            }
          } else if (dataObject && dataObject.market_status === 'late_trading') {
            data = {
              hpUsd: dataObject?.session?.late_trading_change,
              hpPct: dataObject?.session?.late_trading_change_percent,
            }
          } else {
            data = {
              hpUsd: dataObject?.session?.change,
              hpPct: dataObject?.session?.change_percent,
            }
          }
        } else if (type === SecurityType.INDEX && !_.isEmpty(dataObject)) {
          data = {
            hpUsd: dataObject?.session?.change || 0.00,
            hpPct: dataObject?.session?.change_percent || 0.00,
          }
        } else {
          data = {
            hpUsd: 0.00,
            hpPct: 0.00,
          }
        }
      }
      setPriceSummaryData(data)
    } else {
      setPriceSummaryData('')
    }
  }, [securityTickerData])

  useEffect(() => {
    if (selectedSecurities?.type !== SecurityType.BOND && !_.isEmpty(selectedSecurities?.api_data)) {
      let securityPrice = selectedSecurities?.type === SecurityType.STOCK ?
        selectedSecurities?.api_data?.stock_price : selectedSecurities?.api_data?.index_price

      setAnnualRatesPct(securityPrice?.change_1y_pct || '-')
      setAnnualRates((Number(securityPrice?.highest_data?.c || 0) - Number(securityPrice?.lowest_data?.c || 0)).toFixed(2))
    } else {
      setAnnualRatesPct('')
      setAnnualRates('')
    }
  }, [overviewData])

  return (
    <>
      {(priceSummaryData) ?
        <div className="d-flex flex-column mb-4">
          <h1 className="mb-0 align-middle">
            {formatSecuritiesPrice(selectedSaap.code, selectedSaap.price)}

            {/* BEGIN Last year item changes */}
            {
              annualRatesPct ?
              <>
                <span className={
                    `badge badge-light-${annualRatesPct > 0 ? 'success' :
                    (annualRatesPct < 0 ? 'danger' : 'secondary')}
                    fw-bold d-inline-flex fs-6 ps-0 ms-2 align-items-baseline`
                }>
                  <i className={
                    `bi bi-arrow-${annualRatesPct > 0 ? 'up' : 'down'}-short
                    text-${annualRatesPct > 0 ? 'success' :
                      (annualRatesPct < 0 ? 'danger' : 'dark')} fs-3 p-0 fw-bold`}
                  ></i>
                  <span>{Number(annualRatesPct).toFixed(4)}%</span>
                </span>
                <span className={
                  `text-${annualRates > 0 ? 'success' :
                    (annualRates < 0 ? 'danger' : 'muted')} fw-bold d-inline-flex fs-5 ps-0 ms-2`
                }>
                  {annualRates > 0 ? '+' : ''}{annualRates} Last year
                </span>
              </>
              : null
            }
            {/* END Last year item changes */}
          </h1>

          {selectedSecurities?.type !== SecurityType.BOND ?
            <span className="h-15px fw-bold">
              {
                selectedSecurities.type === SecurityType.STOCK && (String(selectedSecurities.data?.market_status) === 'early_trading') ?
                  <span className="text-gray-700 fs-7 me-1">
                    {formatSecuritiesPrice(selectedSaap.code, selectedSecurities.data?.session?.price)}
                  </span>
                  : null
              }
              <span className="text-gray-600 fs-8 me-1">
            {priceSummaryData.hpUsd < 0 ? (
              <span>-{formatSecuritiesPrice(selectedSaap.code, priceSummaryData.hpUsd * -1)}</span>
            ) : (
              <span>+{formatSecuritiesPrice(selectedSaap.code, priceSummaryData.hpUsd)}</span>
            )}
              </span>
              <span className={`me-1 text-${priceSummaryData.hpPct > 0 ? 'success' : 'danger'} fs-8`}>
                <span>{priceSummaryData.hpPct > 0 ? '+' : ''}{priceSummaryData.hpPct}%</span>
              </span>
              <span className="text-gray-600 fs-8">&#183;
                {
                  selectedSecurities.type === SecurityType.STOCK
                    ? (String(selectedSecurities.data?.market_status) === 'early_trading'
                      ? ' Pre-market'
                      : ((String(selectedSecurities.data?.market_status) === 'late_trading' ||
                        String(selectedSecurities.data?.market_status) === 'closed'
                      )
                        ? ' Post-market'
                        : ' Today'))
                    : ' Today'
                }
              </span>
            </span> : null
          }

          {/*  <span className="h-15px">*/}
          {/*    <span className="text-gray-600 fs-8 me-1">*/}
          {/*      {priceSummaryData.acUsd < 0 ? (*/}
          {/*          <span>-{formatSecuritiesPrice(selectedSaap.code, priceSummaryData.acUsd * -1)}</span>*/}
          {/*        ) :*/}
          {/*        <span>+{formatSecuritiesPrice(selectedSaap.code, priceSummaryData.acUsd)}</span>*/}
        {/*      }*/}
        {/*    </span>*/}
        {/*  <span className={`me-1 text-${priceSummaryData.acPct > 0 ? 'success' : 'danger'} fs-8`}>*/}
        {/*   <span>{priceSummaryData.acPct > 0 ? '+' : ''}{priceSummaryData.acPct}%</span>*/}
        {/*  </span>*/}
        {/*  <span className="text-gray-600 fs-8">&#183; Lowest price</span>*/}
        {/*</span>*/}
        </div>
        :
        <h1 className="mb-0">
          {formatSecuritiesPrice(selectedSaap.code, selectedSaap.price)}
        </h1>
      }

      {/* BEGIN Own data preview table */}
      {(securityData && selectedSecurities && (isOwnSecurity || selectedSecurities.type === SecurityType.BOND)) ?
        <div className="text-nowrap overflow-scroll pb-3">
          {(securityData && securityData.length) ? securityData.map((item, idx) => {
            const [key, values] = Object.entries(item)[0]

            return (
              <React.Fragment key={idx}>
                <div
                  className={clsx('d-flex flex-column flex-sm-row justify-content-between mb-2 border-1 border-bottom-dashed border-gray-300',
                    Number(idx) === 0 ? 'border-top-dashed pt-2' : ''
                  )}>
                  <span className="fs-5 fw-bold text-gray-600">
                     {values.label}
                  </span>
                  <span className="fs-5 fs-sm-3 fw-bold text-gray-800">
                    {assetValuesKeys.includes(key) ?
                      formatSecuritiesPrice(selectedSaap.code, values.value || '-') :
                      ((values.type === 'select' && values.value) ? _.capitalize(values.value) : (values.value || '-'))
                    }
                  </span>
                </div>
              </React.Fragment>
            )
          }) : null}
        </div> : null
      }
      {/* END Own data preview table */}

      {/* BEGIN Overview data table */}
      {keyDataList && keyDataList.length ?
        <div className="d-flex flex-column mt-5">
          <h4 className="text-gray-700 border-bottom-dashed border-1 border-gray-300 pb-1">
            Key Data
            {(overviewData && overviewData['Symbol']) ?
              <span className="text-gray-800"> ({overviewData['Symbol']})</span> : null
            }
          </h4>

          <div className="table-responsive">
            <table className="table table-striped gs-1">
              <tbody>
              {keyDataList.map((item, idx) => (
                <tr key={idx}>
                  <td className="text-nowrap fw-bold w-200px">{capitalize(item.name)}:</td>
                  <td className="text-nowrap text-start fw-bold">{item.value}</td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>

        </div> : null
      }
      {/* END Overview data table */}

      {/* BEGIN AnalystRatingChart */}
      {(securityTickerData && Object.keys(securityTickerData).length) ?
        <AnalystRatingChart securityTickerData={securityTickerData} selectedSecurities={selectedSecurities}/> : null
      }
      {/* END AnalystRatingChart */}

      {/* BEGIN Disclaimer text block */}
      {detailsDisclaimerText(consultEmail)}
      {/* END Disclaimer text block */}
    </>
  )
}

export default OverviewTab
