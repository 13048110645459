import React, {useEffect} from 'react'
// import {useLocation} from 'react-router'
import clsx from 'clsx'
import {useLayout} from '../core'
import {DrawerComponent} from '../../assets/ts/components'
import {WithChildren} from '../../helpers'
import {VITE_APP_VARIANT} from '../../../helpers/utils'
import {AppVariant} from '../../../Enums/AppVariants/AppVariant'

const Content: React.FC<WithChildren> = ({children}) => {
  const {classes} = useLayout()
  // const location = useLocation()
  useEffect(() => {
    DrawerComponent.hideAll()
  }, []) // location

  return (
    <div
      id='kt_content_container'
      className={VITE_APP_VARIANT === AppVariant.PLANNER ?
        'd-flex flex-column-fluid align-items-start container-xxl' :
        clsx(classes.contentContainer.join(' '))}
    >
      <div className='content flex-row-fluid' id='kt-content'>
        {children}
      </div>
    </div>
  )
}

export {Content}
