import React, {useContext} from 'react'
import {Button, Col, Form, Input, Row} from "reactstrap"
import Flatpickr from "react-flatpickr"
import {useForm, usePage} from "@inertiajs/react";
import Select from "react-select"
import {useTabContext} from "@metronic/Components/Profile/TabContext/TabContext.jsx"
import _ from "lodash"

const SettingsTab = () => {
  const {props: pageProps} = usePage()
  const profileData = pageProps.profile_data
  const {countriesList} = useTabContext()
  // const countriesList = useRef([])
  // const [country, setCountry] = useState([])

  const {data, setData, post, reset} = useForm({
    first_name: profileData.first_name,
    last_name: profileData.last_name,
    full_phone: profileData.phone,
    email: profileData.email,
    city: profileData.city,
    country: profileData.country,
    canton: profileData.state,
    address: profileData.address,
    postal_code: profileData.postal_code,
    details: profileData.details,
    birthday: profileData.birthday
  })

  const submitHandler = (e) => {
    e.preventDefault()

    post('/profile/update', {
      preserveScroll: true,
    })
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>Personal information</h3>
          </div>
        </div>

        <div className='card-body p-9'>
          <Form onSubmit={submitHandler}>
            <div className='row d-flex align-items-center mb-4'>
              <label className='col-md-4 fw-bold text-muted'>Full Name</label>
              <div className='col-md-8'>
                <Row>
                  <Col className="col-12 col-sm-6 mb-3 mb-sm-0">
                    <Input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder="First name"
                      value={data.first_name}
                      onInput={(e) => {
                        setData('first_name', e.target.value)
                      }}
                      name='first_name'
                    />
                  </Col>
                  <Col className="col-12 col-sm-6">
                    <Input
                      type="text"
                      className="form-control form-control-solid"
                      placeholder="Last name"
                      name="last_name"
                      value={data.last_name}
                      onInput={(e) => {
                        setData('last_name', e.target.value)
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </div>

            <div className='row d-flex align-items-center mb-4'>
              <label className='col-md-4 fw-bold text-muted'>Phone Number</label>
              <div className='col-md-8'>
                <Input
                  type="text"
                  className="form-control form-control-solid"
                  name="full-phone"
                  value={data.full_phone || ''}
                  onInput={(e) => {
                    setData('full_phone', e.target.value)
                  }}
                  placeholder="Phone"
                />
              </div>
            </div>

            <div className='row d-flex align-items-center mb-4'>
              <label className='col-md-4 fw-bold text-muted'>Email Address</label>
              <div className='col-md-8 fv-row'>
                <Input
                  type="email"
                  className="form-control form-control-solid"
                  name="email"
                  value={data.email}
                  onInput={(e) => {
                    setData('email', e.target.value)
                  }}
                  placeholder="Email"
                />
              </div>
            </div>

            <div className='row d-flex align-items-center mb-4'>
              <label className='col-md-4 fw-bold text-muted'>City</label>
              <div className='col-md-8 fv-row'>
                <Input
                  type="text"
                  className="form-control form-control-solid"
                  name="city"
                  value={data.city || ''}
                  onInput={(e) => {
                    setData('city', e.target.value)
                  }}
                  placeholder="City"
                />
              </div>
            </div>

            <div className='row d-flex align-items-center mb-4'>
              <label className='col-md-4 fw-bold text-muted'>Country</label>
              <div className='col-md-8'>
                <Select
                  name="country"
                  options={countriesList}
                  value={countriesList.find(i => i.value === data.country)}
                  onChange={(selectedOptions) => {
                    // setCountry(selectedOptions)
                    setData('country', selectedOptions.value)
                  }}
                  // className="form-select form-select-solid"
                />
              </div>
            </div>

            <div className='row d-flex align-items-center mb-4'>
              <label className='col-md-4 fw-bold text-muted'>Canton</label>
              <div className='col-md-8'>
                <Input
                  type="text"
                  className="form-control form-control-solid"
                  name="canton"
                  value={data.canton || ''}
                  onInput={(e) => {
                    setData('canton', e.target.value)
                  }}
                  placeholder="Canton"
                />
              </div>
            </div>

            <div className='row d-flex align-items-center mb-4'>
              <label className='col-md-4 fw-bold text-muted'>Address</label>
              <div className='col-md-8'>
                <Input
                  type="text"
                  name="address"
                  value={data.address || ''}
                  onInput={(e) => {
                    setData('address', e.target.value)
                  }}
                  className="form-control form-control-solid"
                  placeholder="Address"
                />
              </div>
            </div>

            <div className='row d-flex align-items-center mb-4'>
              <label className='col-md-4 fw-bold text-muted'>Postal Code</label>
              <div className='col-md-8'>
                <Input
                  type="text"
                  className="form-control form-control-solid"
                  name="postal_code"
                  value={data.postal_code || ''}
                  onInput={(e) => {
                    setData('postal_code', e.target.value)
                  }}
                  placeholder="Postal Code"
                />
              </div>
            </div>

            <div className='row d-flex align-items-center mb-4'>
              <label className='col-md-4 fw-bold text-muted'>Birthdate</label>
              <div className='col-md-8'>
                <Flatpickr
                  className="form-control form-control-solid"
                  placeholder='Birthdate'
                  name="birthday"
                  value={data.birthday ? new Date(data.birthday) : ''}
                  onChange={date => {
                    setData('birthday', !_.isEmpty(date) ? date[0].toDateString() : '')
                  }}
                  options={{
                    dateFormat: 'd M, Y',
                    altInput: true,
                  }}
                />
              </div>
            </div>

            <div className='row py-3'>
              <label className='col-md-4 fw-bold text-muted'>Details</label>
              <div className='col-md-8'>
                <Input
                  type="textarea"
                  name="details"
                  value={data.details || ''}
                  onInput={(e) => {
                    setData('details', e.target.value)
                  }}
                  className="form-control form-control-solid"
                  placeholder="Some details about me..."
                />
              </div>
            </div>

            <div className="mt-5 w-100 text-end">
              <Button type="submit" color="primary" className="btn btn-sm btn-primary">Update profile</Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  )
}

export default SettingsTab
