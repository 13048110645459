import React from 'react'
import {SupportContent} from "@metronic/Components"
import {PageTitle} from "@metronic/_metronic/layout/core"
import {Head} from "@inertiajs/react"

const Support = () => {

  return (
    <>
      <Head title={'Support | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>Support</PageTitle>
      <SupportContent />
    </>
  )
}

export default Support
