import clsx from 'clsx'
import React, {useState} from 'react'
import {CreateAppModal} from '../../../partials'
import {useLayout} from '../../core'
import {LightTitle} from '../header/page-title/LightTitle'
import {Link} from "@inertiajs/react";

const Toolbar1Light = ({isWallet, activeTab}) => {
  const {classes} = useLayout()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const VITE_SHOW_SECURITIES = import.meta.env.VITE_SHOW_SECURITIES

  return (
    <>
      <div className='toolbar pb-15' id='kt_toolbar'>
        {/* begin::Container */}
        <div
          id='kt_toolbar_container'
          className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack d-print-none')}
        >
          <LightTitle/>

          <div className='d-flex align-items-center'>
            {/*<div>*/}
            {/*  <a*/}
            {/*    href='#'*/}
            {/*    className='btn btn-custom py-2 px-3 btn-active-white btn-flex btn-color-white btn-active-color-white'*/}
            {/*    data-kt-menu-trigger='click'*/}
            {/*    data-kt-menu-placement='bottom-end'*/}
            {/*    data-kt-menu-flip='top-end'*/}
            {/*  >*/}
            {/*    <KTIcon iconName='questionnaire-tablet' className='fs-5 me-1' />*/}
            {/*    Marks all as read*/}
            {/*  </a>*/}
            {/*</div>*/}


            {/* begin::Button */}

            {(isWallet && VITE_SHOW_SECURITIES === 'true') ?
              <>
                <Link
                  href='/wallet/investments'
                  className={`btn btn-${activeTab === 'investments' ? 'primary' : 'light-primary'} btn-active-white py-1 me-3
                  btn-color-${activeTab === 'investments' ? 'light' : 'dark'} btn-active-color-${activeTab === 'investments' ? 'light' : 'light'}`
                  }
                >
                  Investment details
                </Link>

                <Link
                  href='/wallet'
                  className={`btn btn-${activeTab === 'balance' ? 'primary' : 'light-primary'} btn-active-white py-1
                  btn-color-${activeTab === 'balance' ? 'light' : 'dark'} btn-active-color-${activeTab === 'balance' ? 'light' : 'light'}`
                  }
                >
                  My Balance
                </Link>
              </> : null
            }

            {/*<a*/}
            {/*  className='btn bg-body btn-active-color-primary'*/}
            {/*  id='kt_toolbar_primary_button'*/}
            {/*  data-bs-theme='light'*/}
            {/*  onClick={() => setShowCreateAppModal(true)}*/}
            {/*>*/}
            {/*  Create*/}
            {/*</a>*/}
            {/* end::Button*/}
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Container */}
      </div>
      <CreateAppModal show={showCreateAppModal} handleClose={() => setShowCreateAppModal(false)} />
    </>
  )
}

export {Toolbar1Light}
