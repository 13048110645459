
export default {
  // 2FA Auth
  "2fa_description": "Two factor authentication (2FA) strengthens access security by requiring two methods " +
    "(also referred to as factors) to verify your identity. Two factor authentication protects against phishing, " +
    "social engineering and password brute force attacks and secures your logins from attackers exploiting weak or " +
    "stolen credentials.",
  "Disable 2FA": "Disable 2FA",
  "Generate secret key to Enable 2FA": "Generate secret key to Enable 2FA",
  "Re-generate secret key to Enable 2FA": "Re-generate secret key to Enable 2FA",
  "Please scan the code or enter the security code manually to get your verification code to enable 2FA.": "Please scan " +
    "the code or enter the security code manually to get your verification code to enable 2FA.",
  "Google Authenticator QRCode": "Google Authenticator QRCode",
  "2FA code": "2FA code",
  "Enter verification code from 2FA app here": "Enter verification code from 2FA app here",
  "Verify": "Verify",

  // Progress
  "Your Profile Progress": "Your Profile Progress",
  "Fill more information about you to increace the progress!": "Fill more information about you to increace the progress!",

  //Personal detail
  "Personal Details": "Personal Details",
  "Phone Number": "Phone Number",
  "Email Address": "Email Address",
  "Postal Code": "Postal Code",
  "Birthdate": "Birthdate",
  "Details": "Details",
  "Enter your firstname": "Enter your firstname",
  "Enter your lastname": "Enter your lastname",
  "Enter your phone number": "Enter your phone number",
  "Enter your email": "Enter your email",
  "Enter postal code": "Enter postal code",

  //Change password
  "Change Password": "Change Password",
  "Old Password": "Old Password",
  "New Password": "New Password",
  "Confirm Password": "Confirm Password",
  "Enter current password": "Enter current password",
  "Enter new password": "Enter new password",

  //Documents
  "Documents": "Documents",
  "File Name": "File Name",
  "Upload Date": "Upload Date",
  "Upload File": "Upload File",
  "No data": "No data",

  //Upload File modal
  "Upload document": "Upload document",
  "Document type": "Document type",
  "Files": "Files",
  "Browse": "Browse",
  "No files selected": "No files selected",
  "Submit": "Submit",
  "Choose...": "Choose..."
}
