import React, {useEffect, useState} from 'react'
import {Card, CardBody, Col, Row} from "reactstrap"
import {useTranslation} from "react-i18next"
import bgImage from '@metronic/_metronic/assets/media/auth/bg6.jpg'
import {usePage} from "@inertiajs/react"
import _ from "lodash"
import {toast, ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'

const bgPosition = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundSize: "cover",
  zIndex: '0'
}

const overlay = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  zIndex: '1'
}

const UnsubscribeContent = () => {
  const {t} = useTranslation()
  const [isMouseOnButton, setIsMouseOnButton] = useState(false)
  const [isButtonClicked, setIsButtonClicked] = useState(false)
  const [password, setPassword] = useState('')
  const {props} = usePage()
  const flash = props.flash

  useEffect(() => {
    if (_.size(props.errors)) {
      for (let error in props.errors) {
        toast.error(props.errors[error])
      }
    } else if (flash) {
      if (!flash?.success) {
        toast.error(flash.message)
      } else {
        toast.success(flash.message)
      }
    }
  }, [props])

  return (
    <>
      <ToastContainer/>
      <div className="d-flex flex-column flex-column-fluid position-relative">
        <img
          alt='bg'
          className="img-fluid theme-light-show w-100 h-100"
          src={bgImage}
          style={bgPosition}
        />
        <div style={overlay}></div>
        <Row className="justify-content-center align-items-center position-relative z-index-2 h-100">
          <Col sm={10} md={8} lg={6} xl={5}>
            <Card className="mt-4">
              <CardBody className="p-10">
                <div className="text-center mt-2">
                  <i className="bi bi-mailbox" style={{fontSize: "60px", color: 'red'}}></i>
                  <h1 className="text-primary mb-3 mt-5">{t("You unsubscribed!")}</h1>
                  <span className="text-muted w-100 w-md-75 fs-4">
                    {t("We're sorry to lose you, but we totally understand.")}
                  </span><br/>
                  <span className="text-muted w-100 w-md-75">
                    {t("You can close this window.")}
                  </span>
                </div>
                <div className="p-2 mt-4">
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default UnsubscribeContent
