import React, {FC, useEffect} from 'react'
import {Footer} from '@metronic/_metronic/layout/components/Footer'
import {HeaderWrapperLight} from '@metronic/_metronic/layout/components/header/HeaderWrapper/HeaderWrapperLight'
import {ScrollTop} from '@metronic/_metronic/layout/components/ScrollTop'
import {PageDataProvider} from '@metronic/_metronic/layout/core'
import {themeModeSwitchHelper, useThemeMode} from '@metronic/_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {WithChildren} from '@metronic/_metronic/helpers'
import { AsideLight } from '@metronic/_metronic/layout/components/aside/Light/AsideLight'
import { TransactionsModalProvider } from '@metronic/Components/Transactions/TransactionsDetailsModalsContext'
import Chat from '@metronic/Components/Support/Chat/Chat'

const MasterLayoutLight: FC<WithChildren> = ({children}) => {
  const {mode} = useThemeMode()

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])

  return (
    <PageDataProvider>
      {/*<div className='page d-flex flex-row flex-column'>*/}
        <div className='page d-flex flex-row flex-column-fluid'>
          <AsideLight/>
          <div className='wrapper d-flex flex-column flex-row-fluid content-wrapper-margin' id='kt_wrapper'>
            <HeaderWrapperLight/>
            <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
              <TransactionsModalProvider>
                {children}
              </TransactionsModalProvider>
            </div>
            <Footer/>
          </div>
        </div>
      {/*</div>*/}

      <Chat />
      <ScrollTop/>
    </PageDataProvider>
  )
}

export {MasterLayoutLight}
