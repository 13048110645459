
export default {
  // Balance Widget
  "Balance": "Balance",
  "Available": "Available",
  "Locked": "Locked",
  "Total amount": "Total amount",

  // Account Balance Dropdown
  "Account Balance": "Account Balance",
  "No balance": "No balance",

  // Exchange Form
  "Locked Balance": "Locked Balance",
  "Available Balance": "Available Balance",
  "Sell asset balance": "Sell asset balance",
  "Sell asset": "Sell asset",
  "Buy asset": "Buy asset",
  "Sell amount": "Sell amount",
  "Buy amount": "Buy amount",
  "Transaction Fees": "Transaction Fees",
  "Total sell amount": "Total sell amount",
  "Total pay amount": "Total pay amount",
  "Max sum": "Max sum",
  "Exchange message header": "The amount you receive might differ from what is currently displayed.",
  "Exchange message content": "This exchange rate is guaranteed for 30 minutes after you generate an order. On rare occasions " +
    "where it will take longer than 30 minutes for your payment to be cleared, we will update the exchange rate " +
    "every 1 minute until payment has reached our account.",
  "You receive": "You receive",
  "Paying from Available Balance": "Paying from Available Balance",
  "Select the asset you want to sell with a balance greater than zero.": "Select the asset you want to sell with " +
    "a balance greater than zero.",
  "Buy amount must be greater than zero": "Buy amount must be greater than zero",

  //Verification status icon
  "Not verified": "Not verified",
  "Verified": "Verified",

  // Profile dropdown
  "Profile": "Profile",
  "Logout": "Logout"
}
