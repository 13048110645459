// Core
import React from 'react'
import {Col, Row} from "reactstrap"
// Components
import {Content} from "@metronic/_metronic/layout/components/Content"
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Last5Transactions} from "@metronic/Components/Tables"
import DepositCreditCardForm from "@metronic/Components/Deposit/CreditCard/CreditCardForm/DepositCreditCardForm"

const DepositCreditCardContent = () => {

  return (
    <>
      <Toolbar />
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          <DepositCreditCardForm />
        </Row>

        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          <Col className="col-12">
            <Last5Transactions />
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default DepositCreditCardContent
