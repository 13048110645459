import React, {useEffect, useState} from "react"
import {MenuItem} from '../MenuItem'
import {MenuInnerWithSub} from "../MenuInnerWithSub"
import {router, usePage} from "@inertiajs/react"
import _ from "lodash"

export function MenuInnerDefault() {
  const [securitiesData, setSecuritiesData] = useState([])
  const {props: pageProps} = usePage()
  const showSecurities = import.meta.env.VITE_SHOW_SECURITIES
  const showTrade = import.meta.env.VITE_SHOW_TRADE
  const [currentPage, setCurrentPage] = useState('')
  const pathName = window.location.pathname

  useEffect(() => {
    if (pageProps.securities_pages && pageProps.securities_pages?.length) {
      setSecuritiesData(pageProps.securities_pages?.filter(i => i.visible))
    }
  }, [pageProps])

  useEffect(() => {
    if (pathName) {
      let clearName = pathName.split('/', 2)
      setCurrentPage(clearName ? _.capitalize(clearName[1]) : '')
    }
  }, [pageProps])

  return (
    <>

        <MenuItem title="Dashboard" to='dashboard' currentPage={currentPage} />
        <MenuItem title='Exchange' to='exchange' currentPage={currentPage}/>

        <MenuInnerWithSub
            title='Deposit'
            currentPage={currentPage}
            to='#'
            // icon='element-11'
            // hasArrow={true}
            menuPlacement='bottom-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
        >
            <MenuItem to='deposit.credit-card' title='Credit Card' hasBullet={true} />
            <MenuItem to='deposit.crypto' title='Crypto' hasBullet={true} />
            <MenuItem to='deposit.bank-transfer' title='Bank Transfer' hasBullet={true} />
            {
              showTrade === 'true' ?
                <MenuItem to='p2p-orders.p2pOrders' title='P2P' hasBullet={true} />
                : null
            }
        </MenuInnerWithSub>

        <MenuInnerWithSub
            title='Withdrawal'
            to='#'
            currentPage={currentPage}
            // icon='element-11'
            // hasArrow={true}
            menuPlacement='bottom-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
        >
            <MenuItem to='withdrawal.crypto' title='Crypto' hasBullet={true} />
            <MenuItem to='withdrawal.bank-transfer' title='Bank Transfer' hasBullet={true} />
        </MenuInnerWithSub>

      {/*{showTrade === 'true' ?*/}
      {/*  <MenuInnerWithSub*/}
      {/*    title='Trade'*/}
      {/*    to='#'*/}
      {/*    currentPage={currentPage}*/}
      {/*    menuPlacement='bottom-start'*/}
      {/*    menuTrigger={`{default:'click', lg: 'hover'}`}*/}
      {/*  >*/}
      {/*    <MenuItem to='p2p-orders.p2pOrders' title='P2P' hasBullet={true} />*/}
      {/*  </MenuInnerWithSub> : null*/}
      {/*}*/}

      {(showSecurities === 'true' && securitiesData?.length) ?
        <MenuInnerWithSub
          title='Securities'
          to='#'
          currentPage={currentPage}
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          {(securitiesData && securitiesData?.length)  ?
            securitiesData.map((item, idx) => {
              return (
                <MenuItem
                  key={idx}
                  to='securities.getPage'
                  title={item.name}
                  slug={item.slug}
                  hasBullet={true}
                />
              )
            }) : null
          }
        </MenuInnerWithSub> : null
      }

        <MenuItem to='transactions' title="Transactions" currentPage={currentPage}/>
        <MenuItem to='invoices.index' title='Invoices' currentPage={currentPage}/>
        <MenuItem to='support' title='Support' currentPage={currentPage}/>

    </>
  )
}
