import React, {useState} from 'react'
import {Button, Card, CardBody, CardHeader, Col, Form, Input, Label, Row} from "reactstrap"
import {useTranslation} from "react-i18next"
import {clsx} from "clsx"

import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import {router, useForm} from "@inertiajs/react";

const ChangePasswordTab = () => {
  const { t } = useTranslation()
  const [isOldPassShow, setIsOldPassShow] = useState(false)
  const [isNewPassShow, setIsNewPassShow] = useState(false)
  const [isConfirmPassShow, setIsConfirmPassShow] = useState(false)

  const {data, setData, post, reset} = useForm({
    old_password: '',
    new_password: '',
    new_password_confirmation: '',
  })

  const submitHandler = e => {
    e.preventDefault()

    post('/profile/change-password', {
      preserveScroll: true,
      onSuccess() {
        router.reload({only: ['flash']})
        reset()
      }
    })
  }

  return (
    <>
      <Card>
        <CardHeader>
          <h3 className="card-title fw-bold">Change Password</h3>
        </CardHeader>
        <CardBody>
          <Form onSubmit={submitHandler}>
            <Row className="g-5">
              <Col className={clsx(
                VITE_APP_VARIANT === 'light' ? 'col-12 col-md-4 col-lg-12 col-xl-4 mb-3 mb-md-0 mb-lg-3 mb-xl-0' :
                  'col-12 col-md-4 mb-3 mb-md-0'
              )}>
                <div>
                  <Label htmlFor="oldpasswordInput" className="form-label mb-0">
                    {t("Old Password")}
                    <sup className="text-danger">*</sup>
                  </Label>
                  <div className="position-relative auth-pass-inputgroup">
                    <Input
                      name="old_password"
                      type={isOldPassShow ? "text" : "password"}
                      className="form-control form-control-solid"
                      value={data.old_password}
                      onInput={(e) => {
                        setData('old_password', e.target.value)
                      }}
                      id="oldpasswordInput"
                      placeholder={t("Enter current password")}
                    />
                    <Button
                      color="link"
                      onClick={() => setIsOldPassShow(!isOldPassShow)}
                      className="position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                    >
                      <i
                        className={clsx('bi', !isOldPassShow ? 'bi-eye-fill' : 'bi-eye-slash-fill', 'pb-1 pe-3 fs-3')}></i>
                    </Button>
                  </div>
                </div>
              </Col>

              <Col className={clsx(
                VITE_APP_VARIANT === 'light' ? 'col-12 col-md-4 col-lg-12 col-xl-4 mb-3 mb-md-0 mb-lg-3 mb-xl-0' :
                  'col-12 col-md-4 mb-3 mb-md-0'
              )}>
                <div>
                  <Label htmlFor="newpasswordInput" className="form-label mb-0">
                    {t("New Password")}
                    <sup className="text-danger">*</sup>
                  </Label>
                  <div className="position-relative auth-pass-inputgroup">
                    <Input
                      name="new_password"
                      value={data.new_password}
                      onInput={(e) => {
                        setData('new_password', e.target.value)
                      }}
                      type={isNewPassShow ? "text" : "password"}
                      className="form-control form-control-solid"
                      id="newpasswordInput"
                      placeholder={t("Enter new password")}
                    />
                    <Button
                      color="link"
                      onClick={() => setIsNewPassShow(!isNewPassShow)}
                      className="position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                    >
                      <i
                        className={clsx('bi', !isNewPassShow ? 'bi-eye-fill' : 'bi-eye-slash-fill', 'pb-1 pe-3 fs-3')}></i>
                    </Button>
                  </div>
                </div>
              </Col>

              <Col className={clsx(
                VITE_APP_VARIANT === 'light' ? 'col-12 col-md-4 col-lg-12 col-xl-4 mb-3 mb-md-0 mb-lg-3 mb-xl-0' :
                  'col-12 col-md-4 mb-3 mb-md-0'
              )}>
                <div>
                  <Label htmlFor="confirmpasswordInput" className="form-label mb-0">
                    {t("Confirm Password")}
                    <sup className="text-danger">*</sup>
                  </Label>
                  <div className="position-relative auth-pass-inputgroup">
                    <Input
                      name="new_password_confirmation"
                      value={data.new_password_confirmation}
                      onInput={(e) => {
                        setData('new_password_confirmation', e.target.value)
                      }}
                      type={isConfirmPassShow ? "text" : "password"}
                      className="form-control form-control-solid"
                      id="confirmpasswordInput"
                      placeholder={t("Confirm password")}
                    />
                    <Button
                      color="link"
                      onClick={() => setIsConfirmPassShow(!isConfirmPassShow)}
                      className="position-absolute end-0 top-0 text-decoration-none text-muted"
                      type="button"
                    >
                      <i className={clsx('bi', !isConfirmPassShow ? 'bi-eye-fill' : 'bi-eye-slash-fill' , 'pb-1 pe-3 fs-3')}></i>
                    </Button>
                  </div>
                </div>
              </Col>

              <Col lg={12}>
                <div className="text-end mt-3">
                  <button type="submit" className="btn btn-success btn-sm">{t("Change Password")}</button>
                </div>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  )
}

export default ChangePasswordTab
