import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import ICU from 'i18next-icu'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

// import ru from "./locales/ru/index"
import eng from "./locales/en/index"

// the translations
const resources = {
  // ru: {translation: Object.assign({}, ...Object.values(ru))},
  en: {translation: Object.assign({}, ...Object.values(eng))}
}

// const language = localStorage.getItem("I18N_LANGUAGE")
// if (!language) {
//   localStorage.setItem("I18N_LANGUAGE", "en")
// }
localStorage.setItem("I18N_LANGUAGE", "en")

i18n
  .use(initReactI18next)
  .use(ICU)
  .init({
    resources,
    // lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
