import React, {useEffect, useState} from 'react'
import {Col, Row} from "reactstrap"
import {usePage} from "@inertiajs/react"
//Components
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
import ChatList from "@metronic/Components/Support/ChatList.jsx"
import CreateNewTicketBody from "@metronic/Components/Support/CreateNewTicketBody.jsx";

const CreateTicketContent = () => {
  const {props: pageProps} = usePage()
  const [ticketsList, setTicketsList] = useState([])

  useEffect(() => {
    setTicketsList(pageProps?.tickets?.data ?? [])
  }, [pageProps])

  return (
    <>
      <Toolbar/>
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          <Col className='flex-column col-12 col-lg-5 flex-lg-row-auto'>
            <ChatList ticketsList={ticketsList} />
          </Col>

          <Col className="col-12 col-lg-7">
            <CreateNewTicketBody />
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default CreateTicketContent
