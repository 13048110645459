import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {NotificationsContent} from "@metronic/Components"
import {Head} from "@inertiajs/react"

const Notifications = () => {
  return (
    <>
      <Head title={'Notifications | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>Notifications</PageTitle>
      <NotificationsContent />
    </>
  )
}

export default Notifications
