
import React, {FC} from 'react'
import {useLayout} from '../core'
import {VITE_APP_VARIANT} from '@metronic/helpers/utils'
import {AppVariant} from '../../../Enums/AppVariants/AppVariant'
import clsx from "clsx"

const Footer: FC = () => {
  const {classes} = useLayout()
  const appName = import.meta.env.VITE_APP_NAME

  return (
    <div className={clsx(
      'footer py-4 d-flex flex-lg-column d-print-none mt-auto',
      (VITE_APP_VARIANT === AppVariant.PLANNER || VITE_APP_VARIANT === AppVariant.PLANNER_ORANGE) ? 'container-xxl' : ''
    )} id='kt_footer'>
      {/* begin::Container */}
      <div
        className={
        `${(VITE_APP_VARIANT === AppVariant.PLANNER || VITE_APP_VARIANT === AppVariant.PLANNER_ORANGE) ? '' : classes.footerContainer}
        d-flex flex-column flex-md-row align-items-center justify-content-between`
      }
      >
        {/* begin::Copyright */}
        <div className='text-gray-900 order-2 order-md-1'>
          <span className='text-muted fw-semibold me-2'>{new Date().getFullYear()} &copy;</span>
          <a href='#' className='text-gray-800 text-hover-primary'>
            {appName}
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        {/*<ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>*/}
        {/*  <li className='menu-item'>*/}
        {/*    <a href='#' className='menu-link ps-0 pe-2'>*/}
        {/*      About*/}
        {/*    </a>*/}
        {/*  </li>*/}
        {/*  <li className='menu-item'>*/}
        {/*    <a href='#' className='menu-link pe-0 pe-2'>*/}
        {/*      Contact*/}
        {/*    </a>*/}
        {/*  </li>*/}
        {/*  <li className='menu-item'>*/}
        {/*    <a href='#' className='menu-link pe-0'>*/}
        {/*      Purchase*/}
        {/*    </a>*/}
        {/*  </li>*/}
        {/*</ul>*/}
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
