import React, {useEffect, useState} from 'react'
import {Col, Row} from "reactstrap"
import {assetFullName, copyToClipboard, cryptoIconsMapper, isTwoPrecision} from "@metronic/helpers/utils.jsx"
import {KTIcon} from "@metronic/_metronic/helpers/index"
import {usePage} from "@inertiajs/react"

export const P2POrderPrint = ({order = null}) => {
  const {props: pageProps} = usePage()
  const [userInfo, setUserInfo] = useState('')

  useEffect(() => {
    if (pageProps) {
      setUserInfo(pageProps.auth?.user)
    }
  }, [pageProps])

  return (
    <>
      {order ?
      <>
        <Row>
          <h1 className="w-100 text-center mb-10">Order confirmation</h1>

          <div className="d-flex d-print-none">
            <div
              className="fw-bold fs-4 w-100 p-2 bg-light-info text-center text-gray-700 rounded-2 cursor-pointer"
            >
              {order.meta?.profile_info?.first_name}
            </div>
            <div className="px-3"></div>
            <div
              className="fw-bold fs-4 w-100 p-2 bg-light-info text-center text-gray-700 rounded-2 cursor-pointer"
            >
              {order.meta?.profile_info?.last_name}
            </div>
          </div>

          <div className="d-none d-print-block w-100 fw-bold fs-4 text-center">
            to {order.meta?.profile_info?.first_name} {order.meta?.profile_info?.last_name}
          </div>

          <div className="d-flex flex-row align-items-center mt-3">
            <div
              className="d-flex flex-column align-items-center justify-content-center
                    bg-light-warning p-3 rounded-2 w-100 px-5"
            >
              {/*<span className="fs-4 text-gray-700 mb-2 fw-bold">Amount and asset you are selling</span>*/}
              <div className="d-flex align-items-center">
                <span
                  className="fs-5 fw-bold me-2 bg-light-info p-2 text-gray-700 cursor-pointer"
                >
                  {isTwoPrecision(order.sell_asset?.currency?.code, order.sell_price)}
                  <KTIcon iconName='copy' className='fs-3 ps-2 d-print-none'/>
                </span>
                <img
                  src={cryptoIconsMapper[order.sell_asset?.currency?.code]}
                  alt={order.sell_asset?.currency?.code}
                  className="w-25px me-2"
                />
                <span className="fs-5 fw-bold">{assetFullName[order.sell_asset?.currency?.code]}</span>
              </div>
            </div>

          </div>
        </Row>

        <Row>
          <div className="separator mt-10 mb-5 border-primary separator-content text-nowrap text-primary fw-bold">
            Bank information
          </div>
        </Row>

        <Row className="d-flex flex-column align-items-center">
          {(order && order.meta?.order_details) ?
            Object.entries(order.meta?.order_details).map(([key, val], idx) => {
              return (
                <Col className="col-6 mt-3 d-flex justify-content-center" key={idx}>
                  <span className="label fw-bold mb-0 fs-5 me-2">{key}:</span>
                  <span className="label fw-bold mb-0 fs-5 text-muted">{val}</span>
                </Col>
              )
            }) : null}
        </Row>
      </> : null
      }
    </>
  )
}

export default P2POrderPrint
