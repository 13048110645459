import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {DepositCryptoContent} from "@metronic/Components"
import {Head} from "@inertiajs/react"

const DepositCrypto = () => {
  return (
    <>
      <Head title={'Deposit Crypto | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>Deposit Crypto</PageTitle>
      <DepositCryptoContent />
    </>
  )
}

export default DepositCrypto
