import React, {useEffect, useState} from 'react'
import {Button, Card, CardBody, Col, Row} from "reactstrap"
import {clsx} from "clsx"
import {capitalize} from "lodash"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext"
import {blockCardTitle} from "@metronic/Components/Securities/Components.jsx"
import {SecurityDetail} from "@metronic/Components/Securities/Blocks/index.jsx"
import {checkImageValidity, formatSecuritiesPrice, useWindowSize} from "@metronic/helpers/utils.jsx"
import {KTIcon} from "@metronic/_metronic/helpers/index"
import {VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"

const SecuritiesTable = ({blockId}) => {
  const {
    tabData,
    securitiesList,
    tableRef,
  } = useSecuritiesContext()
  const {width: windowWidth} = useWindowSize()
  const [blockData, setBlockData] = useState({})
  const [isMouseOnRow, setIsMouseOnRow] = useState(0)
  const [selectedSecurities, setSelectedSecurities] = useState({})
  const [imageValidity, setImageValidity] = useState({})
  const [consultEmail, setConsultEmail] = useState('')
  //selectedSecurityAssetAndPrice
  const [selectedSaap, setSelectedSaap] = useState({})

  useEffect(() => {
    if (tabData && tabData.length && blockId) {
      setBlockData(tabData.find(block => block.uuid === blockId))
    }
  }, [tabData, blockId])

  const resetSelectedSecurities = () => {
    setSelectedSecurities({})
  }

  useEffect(() => {
    const validateImages = async () => {
      const results = {}
      for (const item of securitiesList) {
        const meta = item.fields
        if (meta?.logo?.value) {
          results[item.id] = await checkImageValidity(meta.logo.value)
        } else {
          results[item.id] = false
        }
      }
      setImageValidity(results)
    }

    validateImages()
  }, [securitiesList])

  useEffect(() => {
    if (securitiesList) {
      if (Number(windowWidth >= 576) && !selectedSecurities?.name) {
        setSelectedSecurities(securitiesList[0])
      }
    }
  }, [securitiesList, windowWidth])

  useEffect(() => {
    const fields = selectedSecurities?.fields

    setConsultEmail(fields?.['consult_email']?.['value'] || '')

    const payoutAssetCode = fields?.['payout_asset']?.['payout_asset_code'] || 'USD'
    const currentPrice = selectedSecurities?.price || '0'

    setSelectedSaap({
      code: payoutAssetCode,
      price: currentPrice,
    })
  }, [selectedSecurities])

  return (
    <>
      <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
        {(!selectedSecurities?.name || windowWidth >= 576) ?
          <Col className={clsx('col-12', !securitiesList.length ? '' : (
            VITE_APP_VARIANT === 'light' ? 'col-md-5 col-lg-12 col-xl-5 col-xxl-4' : 'col-md-5 col-lg-4'
          ))}>
            <Card className="pb-3">
              <div ref={tableRef} className="pt-7 px-9">{blockCardTitle(blockData.title)}</div>
              <CardBody className={clsx('overflow-y-auto pt-0',
                !selectedSecurities?.name ? '' : (
                  VITE_APP_VARIANT === 'light' ? 'mh-sm-300px mh-md-100 mh-lg-350px mh-xl-100' : 'mh-sm-300px mh-md-100'
                )
              )}
              style={{scrollbarGutter: 'stable'}}
              >

                {!securitiesList.length ?
                  <h2 className="text-center fw-bold text-gray-600">
                    There are no records to display
                  </h2> : null
                }

                <div>
                  {(securitiesList && securitiesList.length) ?
                    securitiesList.map((item, idx) => {
                      const id = idx + 1
                      const fields = item.fields
                      const isValid = imageValidity[item.id]

                      return (
                        <div
                          className={clsx('d-flex align-items-center mb-2 rounded-3 cursor-pointer',
                            isMouseOnRow === id && 'bg-light-success',
                            (selectedSecurities && Number(selectedSecurities.id) === Number(item.id)) && 'bg-light-success'
                          )}
                          key={idx}
                          onClick={() => setSelectedSecurities(
                            securitiesList[idx]
                          )}
                          onMouseEnter={() => setIsMouseOnRow(id)}
                          onMouseLeave={() => setIsMouseOnRow(0)}
                        >

                          {(fields['logo']?.value && isValid !== undefined) ?
                            (
                              isValid ? (
                                  <div className="symbol symbol-40px border p-1 ms-2 me-5">
                                    <img src={fields['logo'].value} alt="image"/>
                                  </div>
                                ) :
                                <div className="symbol symbol-40px border p-1 ms-2 me-5">
                                  <div className="symbol-label fs-2 fw-semibold text-primary">
                                    {item.name.slice(0, 1)}
                                  </div>
                                </div>
                            ) :
                            <div className="symbol symbol-40px border p-1 ms-2 me-5">
                              <div className="symbol-label fs-2 fw-semibold text-primary">
                                {item.name.slice(0, 1)}
                              </div>
                            </div>
                          }

                          <div className='d-flex justify-content-between align-items-center w-100 text-truncate'>
                            <div className="d-flex flex-column text-nowrap text-truncate py-1">
                            <span className="fs-7 text-gray-800 fw-bolder text-hover-primary">{item.name}</span>
                              <span className="fs-9 text-success fw-bolder">{capitalize(item.type)}</span>
                              <span className="fs-9 fw-semibold text-gray-500">
                                Current price: {formatSecuritiesPrice(fields['payout_asset']?.payout_asset_code, item?.price)}
                              </span>
                            </div>
                            <div style={{
                              opacity: (isMouseOnRow === id || (Number(selectedSecurities?.id) === Number(item.id))) ? 1 : 0,
                              transition: 'opacity ease-in-out 0.3s'
                            }}
                            >
                              <KTIcon iconName="double-right" className="fs-1 cursor-pointer me-3"/>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    : null
                  }
                </div>

              </CardBody>
            </Card>
          </Col>
          : null
        }


        {selectedSecurities?.name ?
          <Col className={clsx('',
            VITE_APP_VARIANT === 'light' ? 'col-md-7 col-lg-12 col-xl-7 col-xxl-8' : 'col-12 col-md-7 col-lg-8'
          )}>
            <Card>
              <CardBody>
                <SecurityDetail
                  selectedSecurities={selectedSecurities}
                  resetSelectedSecurities={resetSelectedSecurities}
                  isOwnDetails={false}
                  consultEmail={consultEmail}
                  selectedSaap={selectedSaap}
                  setSelectedSecurities={setSelectedSecurities}
                />
              </CardBody>
            </Card>
          </Col> : null
        }
      </Row>
    </>
  )
}

export default SecuritiesTable
