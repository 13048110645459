import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core/index.ts"
import {InvoiceContent} from "@metronic/Components/index.jsx"
import {Head} from "@inertiajs/react"

const Invoices = () => {
  return (
    <>
      <Head title={'Invoices | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>Invoices</PageTitle>
      <InvoiceContent />
    </>
  )
}

export default Invoices
