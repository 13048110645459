import React, {useContext, useState} from 'react'
import {ModalContext} from "@metronic/Components/Modal/ModalsContext/ModalContext.jsx"
import {router} from "@inertiajs/react"
import {toast} from "react-toastify";
import {clsx} from "clsx";

const Disable2faModal = () => {
  const [oneTimePass, setOneTimePass] = useState('')
  const {closeModal} = useContext(ModalContext)
  const [placeholder, setPlaceholder] = useState('Enter code from your 2FA app here')

  const disabled2faHandler = () => {
    if (!oneTimePass) {
      toast.error('First enter the 6-digit code from your authenticator app')
      return
    }

    router.post('/disable-2fa', {one_time_password: oneTimePass}, {
      preserveScroll: true,
      onSuccess(r) {
        router.reload({only: ['profile_data', 'flash']})
        closeModal()
        setOneTimePass('')
      }
    })
  }

  return (
    <>
      <div className="fw-bold text-gray-600 fs-3 mb-6 text-center">
        Enter the 6-digit code from your authenticator app to
        <span className="px-2 fw-bold text-danger">disable</span>
        Two-Factor Authenticatoion.
      </div>

      <input
        type="text"
        className={clsx('form-control form-control-solid mb-8 fs-3 text-center p-1',
          !placeholder ? 'border border-1 border-gray-500 fw-bold' : ''
          )}
        value={oneTimePass}
        onFocus={() => setPlaceholder('')}
        onBlur={() => {
          if (!oneTimePass) {
            setPlaceholder('Enter code from your 2FA app here')
          }
        }}
        onChange={(e) => setOneTimePass(e.target.value)}
        placeholder={placeholder}
      />

      <div className="d-flex justify-content-between">
        <button
          type="button"
          className="btn btn-sm btn-light-primary fs-4 py-1 fw-bold"
          onClick={closeModal}
        >
          Cancel
        </button>

        <button
          type="button"
          className="btn btn-sm btn-light-danger fs-4 py-1 fw-bold"
          onClick={disabled2faHandler}
        >
          Disable
        </button>
      </div>
    </>
  )
}

export default Disable2faModal
