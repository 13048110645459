import React, {useEffect, useRef, useState} from 'react'
import clsx from "clsx"
import {CardBody} from "reactstrap"
import defaultAvatar from '@metronic/assets/images/users/user-dummy-img.jpg'
import {useWindowSize} from "@metronic/helpers/utils.jsx"
import {Link, useForm, usePage} from "@inertiajs/react"
import ChatBodyScrollBar from "@metronic/Components/Support/ChatBodyScrollBar.jsx"
import _ from "lodash"

const ChatBody = ({initialMessage, comments, ticketId, isDrawer = false}) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState(false)
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const {height: windowHeight} = useWindowSize()
  const messagesEndRef = useRef(null)
  const currentUser = usePage().props.auth.user
  const [currentAvatar, setCurrentAvatar] = useState(defaultAvatar)

  const {setData, post, reset} = useForm({comment: ''})

  const sendMessage = () => {

    post(`/support/comment/${ticketId}`, {
      onSuccess() {
        setMessage('')
        reset()
      }
    })
    // const newMessage = {
    //   user: 2,
    //   type: 'out',
    //   text: message,
    //   time: 'Just now',
    // }
    //
    // bufferMessages.push(newMessage)
    // setMessages(bufferMessages)
    // toggleChatUpdateFlat(!chatUpdateFlag)
    // setMessage('')
    // setTimeout(() => {
    //   bufferMessages.push(messageFromClient)
    //   setMessages(() => bufferMessages)
    //   toggleChatUpdateFlat((flag) => !flag)
    // }, 1000)
  }

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  useEffect(() => {
    if (!_.isEmpty(initialMessage) && comments && comments?.length) {
      setMessages([initialMessage, ...comments])
    } else if (!_.isEmpty(initialMessage)) {
      setMessages([initialMessage])
    }
  }, [comments])

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  useEffect(() => {
    if (currentUser.profile?.avatar_url) {
      setCurrentAvatar(currentUser.profile.avatar_url)
    } else {
      setCurrentAvatar(defaultAvatar)
    }
  }, [currentUser])

  return (
    <>
      <CardBody
        className='card-flush pb-0'
        id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
      >
        {initialMessage ?
          <>
            <div
              style={{maxHeight: windowHeight - 527.5}}
              className={clsx('scroll-y me-n5 pe-5', !isDrawer ? "h-300px h-lg-auto" : '')}
              data-kt-element='messages'
              data-kt-scroll='true'
              data-kt-scroll-activate='{default: true}'
              // data-kt-scroll-max-height='auto'
              // data-kt-scroll-dependencies={
              //   isDrawer
              //     ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
              //     : '#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
              // }
              // data-kt-scroll-wrappers={
              //   isDrawer
              //     ? '#kt_drawer_chat_messenger_body'
              //     : '#kt_content, #kt_app_content, #kt_chat_messenger_body'
              // }
              // data-kt-scroll-offset='5px'
            >
              <ChatBodyScrollBar data={messages} initialMessage={initialMessage} />
            </div>

            <div
              className='card-footer px-0 pt-4'
              id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
            >
        <textarea
          className='form-control form-control-flush mb-3'
          disabled={Number(initialMessage.status) === 4}
          rows={1}
          data-kt-element='input'
          placeholder={Number(initialMessage.status) === 4 ?
            'This chat is closed. If you still have questions - create a new chat' :
            'Type a message'
        }
          value={message}
          onChange={(e) => {
            setMessage(e.target.value)
            setData('comment', e.target.value)
          }}
          onKeyDown={onEnterPress}
        ></textarea>

              <div className='d-flex justify-content-end pt-3'>
                {/*<div className='d-flex align-items-center me-2'>*/}
                {/*  <button*/}
                {/*    className='btn btn-sm btn-icon btn-active-light-primary me-1'*/}
                {/*    type='button'*/}
                {/*    data-bs-toggle='tooltip'*/}
                {/*    title='Coming soon'*/}
                {/*  >*/}
                {/*    <i className='bi bi-paperclip fs-3'></i>*/}
                {/*  </button>*/}
                {/*  <button*/}
                {/*    className='btn btn-sm btn-icon btn-active-light-primary me-1'*/}
                {/*    type='button'*/}
                {/*    data-bs-toggle='tooltip'*/}
                {/*    title='Coming soon'*/}
                {/*  >*/}
                {/*    <i className='bi bi-upload fs-3'></i>*/}
                {/*  </button>*/}
                {/*</div>*/}
                <button
                  className='btn btn-sm btn-primary'
                  type='button'
                  disabled={Number(initialMessage.status) === 4}
                  data-kt-element='send'
                  onClick={sendMessage}
                >
                  Send
                </button>
              </div>
            </div>
          </> :

          <div className="h-300px d-flex flex-column justify-content-center align-items-center mb-5">
            <h2 className="text-muted text-wrap text-center">Select a ticket from the existing ones</h2>
            <h4 className="text-muted lh-lg">or create a new one</h4>
            <Link
              href={route('support.create')}
              type="btn"
              className="btn btn-light-primary py-1 px-3 mt-5"
              // onClick={() => {
              //   isCreateNewTicketForm()
              //   userChatOpen()
              // }}
            >
              Create new ticket
            </Link>
          </div>
        }

      </CardBody>
    </>
  )
}

export default ChatBody
