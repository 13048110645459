import Echo from 'laravel-echo'
import Pusher from "pusher-js"

const createEchoInstance = () => {

  const echoOptions = {
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    wsHost: import.meta.env.VITE_PUSHER_HOST,
    wsPort: import.meta.env.VITE_PUSHER_PORT,
    wssPort: import.meta.env.VITE_PUSHER_PORT,
    forceTLS: false,
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    cluster: 'mt1',
  }

  return new Echo(echoOptions);
}

const echo = createEchoInstance()

export {echo, createEchoInstance}
