import React from "react"
import { PageTitle } from "@metronic/_metronic/layout/core"
import { ExchangeContent } from "@metronic/Components"
import {Head} from "@inertiajs/react"

const Exchange = () => {
  return (
    <>
      <Head title={'Exchange | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>Exchange</PageTitle>
      <ExchangeContent />
    </>
  )
}

export default Exchange
