import React, {FC, useEffect} from 'react'
import {Footer} from '@metronic/_metronic/layout/components/Footer'
import {ScrollTop} from '@metronic/_metronic/layout/components/ScrollTop'
import {PageDataProvider} from '@metronic/_metronic/layout/core'
import {themeModeSwitchHelper, useThemeMode} from '@metronic/_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {WithChildren} from '@metronic/_metronic/helpers'
import { AsidePlanner } from '@metronic/_metronic/layout/components/aside/Planner/AsidePlanner'
import {TransactionsModalProvider} from "@metronic/Components/Transactions/TransactionsDetailsModalsContext.jsx"
import Chat from '@metronic/Components/Support/Chat/Chat'
import {HeaderWrapperPlanner} from "../components/header/HeaderWrapper/HeaderWrapperPlanner"

const MasterLayoutPlanner: FC<WithChildren> = ({children}) => {
  const {mode} = useThemeMode()

  useEffect(() => {
    themeModeSwitchHelper(mode)
  }, [mode])

    return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapperPlanner />
          <TransactionsModalProvider>
            {children}
          </TransactionsModalProvider>
          <div className="d-lg-none">
            <AsidePlanner />
          </div>
          <Footer />
        </div>
      </div>

      <ScrollTop />
      <Chat/>
    </PageDataProvider>
  )
}

export {MasterLayoutPlanner}
