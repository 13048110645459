import React from 'react'
import {Head} from "@inertiajs/react"
import ErrorPageContent from "@metronic/Components/ErrorPageContent.jsx"

const ErrorPage = () => {
  return (
    <>
      <Head title={'Error | ' + import.meta.env.VITE_APP_NAME} />
      <ErrorPageContent />
    </>
  )
}

export default ErrorPage
