import React, {useEffect, useState} from 'react'
import {Suspense} from 'react'
import {AuthInit} from '@metronic/modules/auth'
import {LayoutProvider, LayoutSplashScreen} from '@metronic/_metronic/layout/core'
import {ThemeModeProvider} from '@metronic/_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {MasterInit} from '@metronic/_metronic/layout/MasterInit'
import {MasterLayoutDefault} from "@metronic/_metronic/layout/LayoutsVariations/MasterLayoutDefault.tsx"
import {ModalProvider} from "@metronic/Components/Modal"
import {toast, ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import {router, usePage} from "@inertiajs/react"
import _ from "lodash"
import {createEchoInstance} from "@metronic/ws.js"

let echoEventsConfState = false

function echoEventsConf(userId) {
  if (echoEventsConfState) return

  const echo = createEchoInstance()

  // Debugging connection events
  echo.connector.pusher.connection.bind('connected', function() {
    // console.log('Pusher connected');

    echo.private(`notification.${userId}`)
      .listen('UserNotification', () => router.reload())
      .listen('VerificationStatus', () => router.reload())
      .listen('UserPlanChanged', () => router.reload())
      .listen('BalanceChanged', () => router.reload())
      .listen('TicketUpdated', () => router.reload())
  })

  echoEventsConfState = true
}

const MainDefault = ({children}) => {
  const {props} = usePage()
  const user = props?.auth?.user
  const flash = props.flash
  const [userId, setUserId] = useState('')
  const [userVerificationStatus, setUserVerificationStatus] = useState('')

  useEffect(() => {
    if (props) {
      console.log('general props', props);

      setUserId(user?.id)
      setUserVerificationStatus(user?.profile?.verification_status)

      if (_.size(props.errors)) {
        for (let error in props.errors) {
          toast.error(props.errors[error])
        }
      } else if (flash) {
        toast.success(flash.message)
      }
    }
  }, [props])

  useEffect(() => {
    if (userId) {
      echoEventsConf(userId)
    }
  }, [userId])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <ModalProvider>
                <MasterLayoutDefault>
                  {children}
                </MasterLayoutDefault>
                <ToastContainer />
                <MasterInit />
              </ModalProvider>
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
    </Suspense>
  )
}

export default MainDefault
