import clsx from 'clsx'
import React, {useState} from 'react'
import {useLayout} from '../../core'
import {Link} from "@inertiajs/react"
import {LightTitle} from "../header/page-title/LightTitle"

const Toolbar1Planner = ({isWallet, activeTab}) => {
  const {classes} = useLayout()
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false)
  const VITE_SHOW_SECURITIES = import.meta.env.VITE_SHOW_SECURITIES

  return (
    <>
      <div className='toolbar py-5 py-lg-5 mt-6 mt-lg-0 d-flex flex-column bg-light' style={{marginBottom: '30px'}} id='kt_toolbar'>
        <div
          id='kt_toolbar_container'
          className='d-flex flex-stack h-50px d-print-none container-xxl'
        >
          <LightTitle/>

          <div className='d-flex align-items-center'>
            {/*<div>*/}
            {/*  <a*/}
            {/*    href='#'*/}
            {/*    className='btn btn-custom py-2 px-3 btn-active-white btn-flex btn-color-white btn-active-color-white'*/}
            {/*    data-kt-menu-trigger='click'*/}
            {/*    data-kt-menu-placement='bottom-end'*/}
            {/*    data-kt-menu-flip='top-end'*/}
            {/*  >*/}
            {/*    <KTIcon iconName='questionnaire-tablet' className='fs-5 me-1' />*/}
            {/*    Marks all as read*/}
            {/*  </a>*/}
            {/*</div>*/}


            {/* begin::Button */}

            {(isWallet && VITE_SHOW_SECURITIES === 'true') ?
              <>
                <Link
                  href='/wallet/investments'
                  className={`btn btn-sm py-2 btn-light-primary me-3 fw-bold ${activeTab === 'investments' ? 'active' : ''}`}
                >
                  Investment details
                </Link>

                <Link
                  href='/wallet'
                  className={`btn btn-sm py-2 btn-light-primary fw-bold ${activeTab === 'balance' ? 'active' : ''}`}
                >
                  My Balance
                </Link>
              </> : null
            }


            {/* end::Button*/}
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Container */}
      </div>
    </>
  )
}

export {Toolbar1Planner}
