import React from 'react'
import {Col, Row} from "reactstrap"
// Components
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
import {Last5Transactions} from "@metronic/Components/Tables"
import WithdrawalCryptoCard from "@metronic/Components/Withdrawal/Crypto/WithdrawalCryptoCard"

const WithdrawalCryptoContent = () => {
  return (
    <>
      <Toolbar />
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          <Col>
            <WithdrawalCryptoCard />
          </Col>
        </Row>

        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          <Col className="col-12">
            <Last5Transactions />
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default WithdrawalCryptoContent
