import React from 'react'
import {Col, Row} from "reactstrap"
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
import {useSecuritiesContext} from "@metronic/Components/Securities/Context/SecuritiesContext"

// Blocks
import {
  GeneralInformationBlock,
  GeneralTabs,
  PopularList,
  HowToBuy,
  SecuritiesTable,
  DocumentsAndInformation,
  OwnSecuritiesTable,
} from "@metronic/Components/Securities/Blocks"
import {clsx} from "clsx"

const blocksMapper = {
  description: (blockId) => <GeneralInformationBlock blockId={blockId} />,
  popular_list: (blockId) => <PopularList blockId={blockId} />,
  how_buy: (blockId) => <HowToBuy blockId={blockId} />,
  securities_table: (blockId) => <SecuritiesTable blockId={blockId} />,
  own_securities_table: (blockId) => <OwnSecuritiesTable blockId={blockId} />,
  documents_and_info: (blockId) => <DocumentsAndInformation blockId={blockId} />,
}

const SecuritiesContent = () => {
  const {tabData, isSecuritiesListReady} = useSecuritiesContext()

  return (
    <>
      <Toolbar/>
      <Content>

        <>
          {tabData && tabData.length ?
            tabData.map((block, idx) => {

              let blockName = block.name
              const blockId = block.uuid

              if (blockName === 'securities_table' && block.is_user_list) {
                blockName = 'own_securities_table'
              }

              const BlockComponent = blocksMapper[blockName]

              return (
                <Row key={idx} className="row-cols-1 g-5 g-xl-10 mb-5 mb-xl-10">
                  <Col className="mt-0 mt-lg-3 position-relative">
                    {idx === 0 ? <GeneralTabs/> : null}

                    {BlockComponent ? BlockComponent(blockId) : null}

                    {/*<div className={clsx("h-100 w-100 rounded-2 opacity-75 position-absolute top-0 left-0",*/}
                    {/*  !isSecuritiesListReady ? 'd-flex align-items-center justify-content-center' : 'd-none'*/}
                    {/*)}*/}
                    {/*>*/}
                    {/*  <div className="chat-loader"></div>*/}
                    {/*</div>*/}
                  </Col>
                </Row>
              )
            })
            :
            <GeneralTabs/>
          }
        </>

      </Content>
    </>
  )
}

export default SecuritiesContent
