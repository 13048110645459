import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {CreateTicketContent} from "@metronic/Components/index.jsx"
import {Head} from "@inertiajs/react"

const CreateNewTicket = () => {
  return (
    <>
      <Head title={'Support | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>Support</PageTitle>
      <CreateTicketContent />
    </>
  )
}

export default CreateNewTicket
