import React, {createContext, useContext, useEffect, useState} from 'react'
import {selectListData} from "@metronic/api/general.js"

const TabContext = createContext({})

export const useTabContext = () => {
  return useContext(TabContext)
}

export const TabProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState('overview')
  const [countriesList, setCountriesList] = useState([])

  const getCountriesData = async () => {
    setCountriesList(await selectListData('countries'))
  }

  useEffect(() => {
    ;(async () => {
      await getCountriesData()
    })()
  }, [])

  return (
    <TabContext.Provider value={{ activeTab, setActiveTab, countriesList }}>
      {children}
    </TabContext.Provider>
  )
}
