import React, {useEffect, useState} from "react"
import {MenuItem} from '../MenuItem'
import {MenuInnerWithSub} from "../MenuInnerWithSub"
import {usePage} from "@inertiajs/react"

export function MenuInnerLight() {
  const showSecurities = import.meta.env.VITE_SHOW_SECURITIES
  const showTrade = import.meta.env.VITE_SHOW_TRADE
  const [securitiesData, setSecuritiesData] = useState([])
  const {props: pageProps} = usePage()

  useEffect(() => {
    if (pageProps.securities_pages && pageProps.securities_pages?.length) {
      setSecuritiesData(pageProps.securities_pages?.filter(i => i.visible))
    }
  }, [pageProps])

  return (
    <>

      <MenuInnerWithSub
        title='Deposit'
        to='#'
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem to='deposit.credit-card' title='Credit Card' hasBullet={true} />
        <MenuItem to='deposit.crypto' title='Crypto' hasBullet={true} />
        <MenuItem to='deposit.bank-transfer' title='Bank Transfer' hasBullet={true} />
        {
          showTrade === 'true' ?
            <MenuItem to='p2p-orders.p2pOrders' title='P2P' hasBullet={true} />
            : null
        }
      </MenuInnerWithSub>

      <MenuInnerWithSub
        title='Withdrawal'
        to='#'
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem to='withdrawal.crypto' title='Crypto' hasBullet={true} />
        <MenuItem to='withdrawal.bank-transfer' title='Bank Transfer' hasBullet={true} />
      </MenuInnerWithSub>

      {/*{showTrade === 'true' ?*/}
      {/*  <MenuInnerWithSub*/}
      {/*    title='Trade'*/}
      {/*    to='#'*/}
      {/*    menuPlacement='bottom-start'*/}
      {/*    menuTrigger={`{default:'click', lg: 'hover'}`}*/}
      {/*  >*/}
      {/*    <MenuItem to='p2p-orders.p2pOrders' title='P2P' hasBullet={true} />*/}
      {/*  </MenuInnerWithSub> : null*/}
      {/*}*/}

      {(showSecurities === 'true' && securitiesData?.length) ?
        <MenuInnerWithSub
          title='Securities'
          to='#'
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          {(securitiesData && securitiesData?.length)  ?
            securitiesData.map((item, idx) => {
              return (
                <MenuItem
                  key={idx}
                  to='securities.getPage'
                  title={item.name}
                  slug={item.slug}
                  hasBullet={true}
                />
              )
            }) : null
          }
        </MenuInnerWithSub> : null
      }
    </>
  )
}
