import React, {useEffect, useState} from 'react'
import {Col, Row} from "reactstrap"
import BTC from "../../../../../public/media/svg/shapes/bitcoin.svg"
import ETH from "../../../../../public/media/svg/shapes/ethereum.svg"
import DGC from "../../../../../public/media/svg/shapes/dogecoin.svg"
import clsx from "clsx"

import {toTwoPrecision, VITE_APP_VARIANT} from "@metronic/helpers/utils.jsx"
import {selectListData} from "@metronic/api/general.js"
import {KTIcon} from "@metronic/_metronic/helpers/index"
import {usePage} from "@inertiajs/react";
import {useThemeMode} from "@metronic/_metronic/partials/layout/theme-mode/ThemeModeProvider";

const iconMapper = {
  Bitcoin: BTC,
  Ethereum: ETH,
  Dogecoin: DGC
}

const nameMapper = {
  Bitcoin: 'BTC',
  Ethereum: 'ETH',
  Dogecoin: 'DOGE'
}

const colorMapper = {
  Bitcoin: '#F6E5CA',
  Ethereum: '#f3d6ef',
  Dogecoin: '#bfdde3'
}

const CryptoCardWidget = () => {
  const [cryptoMapper, setCryptoMapper] = useState([])
  const [rates, setRates] = useState([])
  const accountBalance = usePage().props.account_balance
  const {mode} = useThemeMode()

  const getRatesData = () => {
    selectListData('crypto_rates').then(r => {
      setRates(r)
    })
  }

  useEffect(() => {
    if (rates && rates.length) {
      const ratesName = ['Bitcoin', 'Ethereum', 'Dogecoin']
      const newRates = rates.filter(rate => ratesName.includes(rate.name))

      setCryptoMapper(
        newRates.map((i, idx) => {
          return (
            {
              name: i.name,
              price: i.price,
              percentExchange: i.volume['1d'].percentExchangeVolume,
              volumeUsd24Hr: i.volume['1d'].volumeUsd24Hr,
              icon: iconMapper[i.name],
              iconName: nameMapper[i.name],
              bgColor: mode === 'dark' ? '#15171c' : colorMapper[i.name]
            }
          )
        })
      )
    }
  }, [rates, mode])

  useEffect(() => {
    getRatesData()
  }, [])

  return (
    <>
      <Row className="gx-xl-10 h-100">
        {cryptoMapper &&
          cryptoMapper.map((el, idx) => {
            return (
              <Col key={idx} className={clsx('mb-5 mb-md-0',
                VITE_APP_VARIANT === 'light' ? 'col-12 col-md-4 col-lg-12 col-xl-4 mb-lg-5 mb-xxl-0' : 'col-12 col-md-4'
              )}>
                <div className="card card-flush h-100" style={{backgroundColor: mode === 'dark' ? '#15171c' : el.bgColor}}>
                  <div className="card-header flex-nowrap align-items-md-center p-2 px-5 p-md-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold fs-4 text-gray-800 d-flex align-items-center">
                        <div className="me-3">
                          {el.name}
                        </div>
                          <div className="d-flex align-items-center">
                            <KTIcon iconName={`arrow-${Number(el.percentExchange) > 0 ? 'up' : 'down'}-refraction`}
                                    className={`text-${Number(el.percentExchange) > 0 ? 'success' : 'danger'} fs-2`}/>
                            <span className={`text-${Number(el.percentExchange) > 0 ? 'success' : 'danger'} fs-7 ps-2`}>
                              {Number(el.percentExchange).toFixed(2)}%
                            </span>
                          </div>
                      </span>
                      <span className="mt-1 fw-semibold fs-7">
                       Volume in USD for 24h: <br/>
                        ${Intl.NumberFormat().format(toTwoPrecision(el.volumeUsd24Hr))}
                      </span>
                    </h3>

                    <div className={clsx('text-center justify-content-end align-items-center',
                      VITE_APP_VARIANT === 'light' ? 'd-flex d-md-none d-lg-flex d-xl-none' : 'd-flex d-md-none'
                    )}>
                      <div className="text-end">
                        <span className="d-block fw-bold fs-1 text-gray-800">
                          ${Intl.NumberFormat().format(toTwoPrecision(el.price))}
                        </span>
                        <span className="mt-1 fw-semibold fs-3">for 1 {el.iconName}</span>
                      </div>
                      <img src={el.icon} className="h-30px mt-1 ps-2" alt=""/>
                    </div>

                    {/*<div className="card-toolbar">*/}
                    {/*  <button className="btn btn-icon justify-content-end"*/}
                    {/*          data-kt-menu-trigger="click"*/}
                    {/*          data-kt-menu-placement="bottom-end" data-kt-menu-overflow="true"*/}
                    {/*  >*/}
                    {/*    <i className="ki-duotone ki-dots-square fs-1">*/}
                    {/*      <span className="path1"></span>*/}
                    {/*      <span className="path2"></span>*/}
                    {/*      <span className="path3"></span>*/}
                    {/*      <span className="path4"></span>*/}
                    {/*    </i>*/}
                    {/*  </button>*/}
                    {/*<div*/}
                    {/*  className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"*/}
                    {/*  data-kt-menu="true">*/}
                    {/*  <div className="menu-item px-3">*/}
                    {/*    <div className="menu-content fs-6 text-gray-900 fw-bold px-3 py-4">Quick*/}
                    {/*      Actions*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*  <div className="separator mb-3 opacity-75"></div>*/}
                    {/*  <div className="menu-item px-3">*/}
                    {/*    <a href="#" className="menu-link px-3">Deposit</a>*/}
                    {/*  </div>*/}
                    {/*  <div className="menu-item px-3">*/}
                    {/*    <a href="#" className="menu-link px-3">Withdrawal</a>*/}
                    {/*  </div>*/}

                    {/*</div>*/}
                    {/*</div>*/}
                  </div>
                  <div className={clsx('card-body text-center pt-5',
                  VITE_APP_VARIANT === 'light' ? 'd-none d-md-block d-lg-none d-xl-block' : 'd-none d-md-block'
                  )}>
                    <img src={el.icon} className="h-100px mb-5" alt=""/>
                    <div className="text-center">
                      <span className="d-block fw-bold fs-1 text-gray-800">
                        ${Intl.NumberFormat().format(toTwoPrecision(el.price))}
                      </span>
                      <span className="mt-1 fw-semibold fs-3">for 1 {el.iconName}</span>
                    </div>
                  </div>
                </div>
              </Col>
            )
          })
        }
      </Row>
    </>
  )
}

export default CryptoCardWidget
