import React from 'react'
import InvoiceDetails from "@metronic/Components/Invoices/InvoiceDetails"
import {PageTitle} from "@metronic/_metronic/layout/core/index"
import {Head, usePage} from "@inertiajs/react"

const SingleInvoice = () => {
  const {props: pageProps} = usePage()
  const pageTitle = `Invoice ${pageProps?.invoice?.uniq_id}`

  return (
    <>
      <Head title={pageTitle + ' | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>{pageTitle}</PageTitle>
      <InvoiceDetails/>
    </>
  )
}

export default SingleInvoice
