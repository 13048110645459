
export default {
  // Credit Card
  "Enter payment information": "Enter payment information",
  "Enter the amount in USD": "Enter the amount in USD",
  "Deposit Amount": "Deposit Amount",
  "Well Done !": "Well Done !",
  "NEXT": "NEXT",
  "Card info": "Card info",
  "Card number": "Card number",
  "MM/YY": "MM/YY",
  "PREVIOUS": "PREVIOUS",
  "Billing info": "Billing info",
  "Country": "Country",
  "Canton": "Canton",
  "Canton*": "Canton*",
  "Postal/ZIP code": "Postal/ZIP code",
  "Postal/ZIP code*": "Postal/ZIP code*",
  "Address": "Address",
  "Address*": "Address*",
  "City": "City",
  "City*": "City*",
  "Phone": "Phone",
  "NEW DEPOSIT": "NEW DEPOSIT",
  "Deposit declined!": "Deposit declined!",
  "Error !": "Error !",
  "deposit_declined_text" : "Your request has been declined. This type of deposit is currently " +
    "unavailable in your country. <br /> Please contact support for additional information",
  "deposit_pending_text": "You request for deposit has been submitted, " +
    "status \"Pending\". <br /> If it will be necessary, we will contact you",
  "Please enter Deposit Amount": "Please enter Deposit Amount",
  "Please enter a valid Card Number (16 digits)": "Please enter a valid Card Number (16 digits)",
  "Please enter a valid Expiry Date (MM/YY)": "Please enter a valid Expiry Date (MM/YY)",
  "Please enter a valid CVC (3 digits)": "Please enter a valid CVC (3 digits)",
  "Please enter Canton": "Please enter Canton",
  "Please enter Address": "Please enter Address",
  "Please enter Postal Code": "Please enter Postal Code",
  "Please enter City": "Please enter City",
  "Please enter Phone Number": "Please enter Phone Number",

  // Crypto
  "Crypto Deposit": "Crypto Deposit",
  "Currency": "Currency",
  "Total": "Total",
  "DEPOSIT": "DEPOSIT",

  // Deposit details
  "Deposit details": "Deposit details",
  "Amount in USD": "Amount in USD",
  "Amount in asset": "Amount in asset",

  // Bank details
  "Banks List": "Banks List",
  "Bank deposit is temporary disabled !": "Bank deposit is temporary disabled !",
  "This information is not currently available.": "This information is not currently available.",
  "For more details, please contact support.": "For more details, please contact support.",
  "BANK INFORMATION": "BANK INFORMATION",
  "Loading": "Loading",
  "Banks list is empty.": "Banks list is empty."
}
