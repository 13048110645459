import React from 'react'
import {PageTitle} from "@metronic/_metronic/layout/core"
import {WithdrawalBankTransferContent} from "@metronic/Components"
import {Head} from "@inertiajs/react"

const WithdrawalBankTransfer = () => {

  return (
    <>
      <Head title={'Withdrawal Bank Transfer | ' + import.meta.env.VITE_APP_NAME} />
      <PageTitle breadcrumbs={[]}>Withdrawal Bank Transfer</PageTitle>
      <WithdrawalBankTransferContent />
    </>
  )
}

export default WithdrawalBankTransfer
