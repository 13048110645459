
export default {
  "Bill to": "Bill to",
  "ITEM": "ITEM",
  "QUANTITY": "QUANTITY",
  "RATE": "RATE",
  "AMOUNT": "AMOUNT",
  "Sub Total": "Sub Total",
  "Terms": "Terms",
  "Discount": "Discount",
  "Tax": "Tax",
  "Back": "Back",
  "Print": "Print",
  "Invoice": "Invoice",
  "days": "days",
  "day": "day",
  "Do you want to pay by crypto?": "Do you want to pay by crypto?",
  "Do you want to pay from your balance?": "Do you want to pay from your balance?"
}
