
export default {
  // Crypto
  "Withdraw Crypto": "Withdraw Crypto",
  "Available_w": "Available",
  "Remaining Daily Withdrawal Amount": "Remaining Daily Withdrawal Amount",
  "Unlimited": " Unlimited",
  "Select currency . . . ": "Select currency . . . ",
  "Fee": "Fee",
  "Withdrawal Fee": "Withdrawal Fee",
  "Estimated time": "Estimated time",
  "Withdrawal Amount": "Withdrawal Amount",
  "Paying from Available Balance": "Paying from Available Balance",
  "Total Amount": "Total Amount",
  "Withdraw Address": "Withdraw Address",
  "WITHDRAW": "WITHDRAW",
  "You receive": "You receive",
  "Network is busy. Gas prices are high and estimates are less accurate.": "Network is busy. Gas prices are high " +
    "and estimates are less accurate.",
  "Low": "Low",
  "Economy": "Economy",
  "Normal": "Normal",
  "High": "High",
  "Your balance exceeds the limit for this type of fee.": "Your balance exceeds the limit for this type of fee.",
  "Please select currency": "Please select currency",
  "Please enter an amount": "Please enter an amount",
  "Please enter an withdrawal address": "Please enter an withdrawal address",

  // Bank transfer
  "Enter payment information": "Enter payment information",
  "Contacts": "Contacts",
  "Contact": "Contact",
  "Account holder": "Account holder",
  "Bank": "Bank",
  "Account number": "Account number",
  "Swift code": "Swift code",
  "Payment reference number": "Payment reference number",
  "Select country": "Select country",
  "NEW BANK TRANSFER": "NEW BANK TRANSFER",
  "You request for a bank transfer has been submitted, status \"Pending\".": "You request for a bank transfer " +
    "has been submitted, status \"Pending\".",
  "If will be necessary, we will contact you.": "If will be necessary, we will contact you.",
  "Minimum withdrawal amount is $": "Minimum withdrawal amount is $",
  "Please select a country": "Please select a country",
  "Please select a canton": "Please select a canton",
  "Please select an address": "Please select an address",
  "Please select a postal code": "Please select a postal code",
  "Please select a city": "Please select a city",
  "Please select a phone": "Please select a phone",
  "Please enter account holder": "Please enter account holder",
  "Please enter bank": "Please enter bank",
  "Please enter city": "Please enter city",
  "Please enter account number": "Please enter account number",
  "Please enter iban": "Please enter IBAN",
  "Please enter swift code": "Please enter swift code",
  "5 days": "5 days",
  "1 day": "1 day",
  "24 hours": "24 hours",
  "6 hours": "6 hours",
  "2 hours": "2 hours",
  "30 min": "30 min"
}
