import BaseTable from "@metronic/Components/Tables/BaseTable"
import React, {useMemo, useRef, useState} from "react"
import {Button, Card, CardBody, Col, Row} from "reactstrap"
import {prettyDate} from "@metronic/helpers/utils"
import {useTranslation} from "react-i18next"
import {Toolbar} from "@metronic/_metronic/layout/components/toolbar/Toolbar"
import {Content} from "@metronic/_metronic/layout/components/Content"
import {Link, router} from "@inertiajs/react";

const InvoiceContent = () => {
  const baseTableMethodsRef = useRef(null)
  const { t } = useTranslation()
  const [selectedRows, setSelectedRows] = useState([])
  const selectedRowsHandler = (row) => {setSelectedRows(row)}

  const cryptoPayHandler = (id) => {
    if (confirm(t('Do you want to pay by crypto?'))) {
      router.post('/invoices/crypto-pay', {
        id: id
      })
    }
  }

  const balancePayHandler = (id) => {
    if (confirm(t('Do you want to pay from your balance?'))) {
      router.post('/invoices/balance-pay', {
        id: id
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        id: "id",
        name: <span className='fw-bold fs-5'>{t("Invoice ID")}</span>,
        selector: (row) => (
          // <>{row.uniq_id}</>
          <Link href={route('invoices.show', {id: row.id})} className="fw-bold cursor-pointer">
            {row.uniq_id}
          </Link>
        ),
        sortable: true,
      },
      {
        id: "status",
        name: <span className='fw-bold fs-5'>{t("Status")}</span>,
        selector: row => (<span className={'badge fw-bold badge-' + row.status_color}>{t(`${row.status_upper}`)}</span>
        ),
      },
      {
        id: "invoicing_date",
        name: <span className='fw-bold fs-5'>{t("Invoicing Date")}</span>,
        selector: row => <span className="fw-bold">{prettyDate(row.formatted_invoicing_date, false)}</span>,
      },
      {
        id: "due_date",
        name: <span className='fw-bold fs-5'>{t("Due Date")}</span>,
        selector: row => <span className="fw-bold">{prettyDate(row.formatted_due_date, false)}</span>,
      },
      {
        id: "payout_asset",
        name: <span className='fw-bold fs-5'>{t("Payout Asset")}</span>,
        selector: row => <span className="fw-bold">{row.formatted_payout_asset}</span>,
      },
      {
        id: "amount",
        name: <span className='fw-bold fs-5'>{t("Amount")}</span>,
        selector: row => <span className="fw-bold">{row.formatted_total_amount}</span>,
      },
      {
        id: "actions",
        width: "240px",
        name: <span className='fw-bold fs-5'>{t("Actions")}</span>,
        selector: row => (
          <>
            <div className="d-flex flex-nowrap">
              <Button
                disabled={['paid', 'declined'].includes(row.status)}
                className="btn-sm me-2 fw-bold"
                color="primary"
                onClick={() => cryptoPayHandler(row.id)}
              >
                {t("Top Up")}
              </Button>
              <Button
                disabled={['paid', 'declined'].includes(row.status)}
                className="btn-sm btn-success fw-bold"
                onClick={() => balancePayHandler(row.id)}
              >
                {t("Pay from balance")}
              </Button>
            </div>
          </>
        ),
      },
    ], [t])

  const updateTable = () => {
    baseTableMethodsRef.current.updateTable()
  }

  return (
    <>
      <Toolbar/>
      <Content>
        <Row className="g-5 g-xl-10 mb-5 mb-xl-10">
          <Col className="col-12">
            <Card>
              <CardBody>
                <BaseTable
                  columns={columns}
                  title={t("Invoices")}
                  selectableRows={false}
                  methodsRef={baseTableMethodsRef}
                  selectedRowsHandler={selectedRowsHandler}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Content>
    </>
  )
}

export default InvoiceContent
