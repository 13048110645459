import React, {useEffect, useState} from 'react'

const colorMap = {
  'Strong Buy': 'success',
  Buy: 'success opacity-75',
  Hold: 'primary',
  Sell: 'warning',
  'Strong Sell': 'danger'
}

const AnalystRatingChart = ({securityTickerData}) => {
  const [analystRating, setAnalystRating] = useState([])
  const [maxVal, setMaxVal] = useState(null)

  useEffect(() => {
    if (securityTickerData && securityTickerData.stock_overview_data?.data) {
      let data = securityTickerData.stock_overview_data?.data
      const subString = 'Analyst Rating '
      let values = []

      for (const key in data) {
        if (String(key).includes(subString)) {
          values = [
            ...values,
            {
              name: String(key).replace(subString, ''),
              val: data[key]
            }
          ]
        }
      }

      let max = values.reduce((max, current) => {
        return parseInt(current.val) > parseInt(max.val) ? current : max
      }, values[0])

      setMaxVal(max?.val)
      setAnalystRating(values)
    } else {
      setAnalystRating([])
    }
  }, [securityTickerData])

  return (
    (analystRating && analystRating.length) ?
    <div className="d-flex flex-column">
      <h4 className="text-gray-700 border-bottom-dashed border-1 border-gray-300 pb-1">Analyst Rating</h4>
      <div className="w-100">
        {(analystRating && analystRating.length) ?
          analystRating.map((item, idx) => {
            let pctWidth = (Number(item.val) === Number(maxVal)) ? 100 :
              ((Number(item.val) / Number(maxVal)) * 100) === 0 ? 1 : ((Number(item.val) / Number(maxVal)) * 100)

            return (
              <div className="mt-1 fs-7 d-flex flex-column" key={idx}>
                <div className="text-gray-700 text-nowrap">{item.name} &#183; {item.val}</div>
                <div
                  className={`bg-${colorMap[item.name]} rounded h-10px`}
                  role='progressbar'
                  style={{width: `${pctWidth}%`}}
                >
                </div>
              </div>
            )
          })
          : null
        }
      </div>
    </div> : null
  )
}

export default AnalystRatingChart
