import clsx from 'clsx'
import React, {useEffect, useState} from 'react'
import {KTIcon} from '@metronic/_metronic/helpers'
import {useLayout} from '@metronic/_metronic/layout/core'
import {HeaderLight} from '@metronic/_metronic/layout/components/header/Header/HeaderLight'
import {LightTitle} from '../page-title/LightTitle'
import {TopbarLight} from '@metronic/_metronic/layout/components/header/TopBar/TopbarLight'
import {useThemeMode} from "../../../../partials/layout/theme-mode/ThemeModeProvider"

const logosUrl = '/storage/brand_logos/'
const logoLightSmUrl = logosUrl + 'logo-light.png'
const logoDarkSmUrl = logosUrl + 'logo-dark.png'

export function HeaderWrapperLight() {
  const {config, classes, attributes} = useLayout()
  const {header, aside} = config
  const [logoVariant, setLogoVariant] = useState('')
  const {mode} = useThemeMode()

  useEffect(() => {
    if (mode === 'dark') {
      setLogoVariant(logoLightSmUrl)
    } else {
      setLogoVariant(logoDarkSmUrl)
    }
  }, [mode])

  return (
    <div className='header-container'>
      <div
        id='kt_header'
        className={clsx('header', classes.header.join(' '), 'align-items-stretch border-0 d-print-none')}
        data-kt-sticky='true'
        data-kt-sticky-name='header'
        data-kt-sticky-offset="{default: '200px', lg: '300px'}"
        {...attributes.headerMenu}
      >
        <div
          className={clsx(classes.headerContainer.join(' '), 'd-flex align-items-center justify-content-between ps-5')}>
          {/* begin::Aside mobile toggle */}
          {aside.display && (
            <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
              <div
                className='btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px'
                id='kt_aside_mobile_toggle'
              >
                <KTIcon iconName='abstract-14' className='fs-2x mt-1'/>
              </div>
            </div>
          )}
          {/* end::Aside mobile toggle */}

          {/* BEGIN Logo */}
          <div className='header-logo me-1 flex-grow-1 flex-lg-grow-0 d-block d-lg-none'>
            <img
              alt=''
              src={logoVariant || ''}
              className='logo-default h-50px'
            />
            <img
              alt=''
              src={logoVariant || ''}
              className='logo-sticky h-50px'
            />
          </div>
          {/* END Logo */}

          {/* begin::Wrapper */}
          <div className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
            {/* begin::Navbar */}
            {header.left === 'menu' && (
              <div className='d-flex align-items-stretch' id='kt_header_nav'>
                <HeaderLight/>
              </div>
            )}

            {header.left === 'page-title' && (
              <div className='d-flex align-items-center' id='kt_header_nav'>
                <LightTitle/>
              </div>
            )}

            <div className='d-flex align-items-stretch flex-shrink-0'>
              <TopbarLight/>
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
      </div>
    </div>
  )
}
