import React, {useEffect, useState} from 'react'
import {Tooltip} from "react-tooltip"
import {usePage} from "@inertiajs/react"
import _ from "lodash"
import {formatSecuritiesPrice} from "@metronic/helpers/utils.jsx"

const InvestmentsDetailsTable = ({currentList, isSecuritiesListReady}) => {
  const {props: pageProps} = usePage()
  const acquiredList = pageProps?.acquired_securities
  const [isUrlsValid, setIsUrlsValid] = useState([])
  const [filteredAcquiredList, setFilteredAcquiredList] = useState([])

  useEffect(() => {
    if (!_.isEmpty(acquiredList)) {
      setFilteredAcquiredList(acquiredList.filter(i => i.quantity > 0))
    }
  }, [acquiredList])

  return (
    <div className="card min-h-100">
      <div className="card-header border-0 pt-5">
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label text-gray-700 fw-bold fs-3 mb-1'>Total investment list</span>
        </h3>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-hover align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold fs-5 text-gray-700 bg-light'>
                <th className='ps-4 sticky bg-light'>Name</th>
                <th className='min-w-60px'>Type</th>
                <th className='text-nowrap'>Current price</th>
                {/*<th title="Average Purchase Price">APP</th>*/}
                <th className="text-nowrap">Daily Change (%)</th>
                {/*<th>Acquired</th>*/}
                {/*<th>Currency</th>*/}
                <th className="text-nowrap">Total investments</th>
                <th title="Profit and Loss">PNL</th>
                <th className='text-nowrap' title="Profit and Loss pct">PNL (%)</th>
                <th>Equity</th>
              </tr>
            </thead>

            <tbody>
            {(!_.isEmpty(filteredAcquiredList) && isSecuritiesListReady) ?
              filteredAcquiredList.map((i, idx) => {
                const security = i.security
                // const transfers = i.transfers
                const sFields = security.fields
                // let totalInvestment = transfers.reduce((acc, i) => {
                //   // return acc + (!_.isEmpty(i.base_amount) ? Math.abs(i.base_amount) : 0)
                //   return acc + (!_.isEmpty(i.base_amount) ? -(i.base_amount) : 0)
                // }, 0)
                let totalInvestment = -(i.transfers_sum) ?? 0
                // let APP = Number(totalInvestment / i.quantity).toFixed(2)
                let assetCode = sFields?.payout_asset?.payout_asset_code
                // let currentPrice = sFields?.current_price?.value ? sFields?.current_price?.value :
                //   (currentList ? currentList.find(i => i?.ticker && i?.ticker?.value === sFields?.ticker?.value) : 0)
                let currentPrice = security.price
                // let APP = Number((currentPrice * i.quantity) / i.quantity).toFixed(2)
                // let priceChangePct = Number(((currentPrice - APP) / currentPrice) * 100).toFixed(2)
                let priceChangePct = Number(security?.data?.session?.change_percent || 0).toFixed(2)
                let pnl = (currentPrice * i.quantity) - totalInvestment
                let pnlPct = Number((Number(pnl) / Number(totalInvestment)) * 100).toFixed(2)

                return (
                  <tr key={idx}>
                    <td className="py-2 m-1 text-truncate sticky bg-body mw-200px">
                      <div className="d-flex align-items-center ms-3">
                        <div className='symbol symbol-25px'>
                          {(sFields?.logo?.value && !isUrlsValid.includes(String(idx))) ?
                            <img
                              src={sFields?.logo?.value}
                              className='align-self-end border'
                              alt='image'
                              onError={() => setIsUrlsValid(prev => ([...prev, String(idx)]))}
                            /> :
                            <div className="symbol-label border fs-2 fw-semibold text-primary">
                              {security.name.slice(0, 1)}
                            </div>
                          }
                        </div>
                        <div
                          className="d-flex ms-2 align-items-center"
                          data-tooltip-id={security.name}
                          data-tooltip-content={security.name}
                        >
                          <span className="fs-5 d-block fw-bold mw-150px text-truncate">{security.name}</span>
                        </div>
                        <Tooltip id={security.name}/>
                      </div>
                    </td>
                    <td className="py-2 m-1">
                      <span className='fw-bold d-block fs-5'>{_.capitalize(security.type)}</span>
                    </td>
                    <td className="py-2 m-1">
                      <span className='fw-bold d-block fs-5'>
                        {formatSecuritiesPrice(assetCode, Number(currentPrice).toFixed(2))}
                      </span>
                    </td>
                    {/*<td className="py-1 m-1">*/}
                    {/*  <span className='fw-bold d-block fs-5'>*/}
                    {/*    {formatSecuritiesPrice(assetCode, APP)}*/}
                    {/*  </span>*/}
                    {/*</td>*/}
                    <td className="py-1 m-1">
                      <span
                        className={`badge ${priceChangePct > 0 ? 'badge-light-success' :
                          (priceChangePct < 0 ? 'badge-light-danger' : 'badge-light text-muted')} fw-bold d-inline-flex fs-5`
                        }
                      >
                        <i className={`bi ${priceChangePct > 0 ? 'bi-arrow-up-short fs-2' :
                          (priceChangePct < 0 ? 'bi-arrow-down-short fs-2' :
                            priceChangePct === '0.00' ? 'bi-arrows-collapse fs-3 me-1' : '')
                        } p-0 fw-bolder text-${priceChangePct > 0 ? 'success' :
                          (priceChangePct < 0 ? 'danger' : 'muted')}`}
                        ></i>
                        {priceChangePct}%
                      </span>
                    </td>
                    {/*<td className="py-2 m-1">*/}
                    {/*  <span className='fw-bold d-block fs-5'>12</span>*/}
                    {/*</td>*/}
                    {/*<td className="py-2 m-1">*/}
                    {/*  <div className="d-flex align-items-center">*/}
                    {/*    <div className='symbol-label' style={{height: '25px'}}>*/}
                    {/*      <img*/}
                    {/*        src={cryptoIconsMapper['USD']}*/}
                    {/*        className='h-75 align-self-end'*/}
                    {/*        alt=''*/}
                    {/*      />*/}
                    {/*    </div>*/}
                    {/*    <span className="ms-2 d-block fs-5 fw-bold">USD</span>*/}
                    {/*  </div>*/}
                    {/*</td>*/}
                    <td className="py-2 m-1">
                      <span className='fw-bold d-block fs-5'>
                        {formatSecuritiesPrice(assetCode, totalInvestment.toFixed(2))}
                      </span>
                    </td>
                    <td className="py-1 m-1">
                      <span
                        className={`text-${pnl.toFixed(2) >= 0.01 ? 'success' :
                          (pnl.toFixed(2) <= -0.01 ? 'danger' : 'muted')} fw-bold d-inline-flex text-nowrap fs-5`}
                      >
                        {pnl.toFixed(2) >= 0.01 ? '+' : (pnl.toFixed(2) <= -0.01 ? '-' : '')}
                        {formatSecuritiesPrice(assetCode, Math.abs(pnl).toFixed(2))}
                      </span>
                    </td>
                    <td className="py-1 m-1">
                      <span
                        className={`badge ${pnlPct > 0 ? 'badge-light-success' :
                          (pnlPct < 0 ? 'badge-light-danger' : 'badge-light text-muted')} fw-bold d-inline-flex fs-5`
                        }
                      >
                        <i className={`bi ${pnlPct > 0 ? 'bi-arrow-up-short fs-2' :
                          (pnlPct < 0 ? 'bi-arrow-down-short fs-2' :
                            pnlPct === '0.00' ? 'bi-arrows-collapse fs-3 me-1' : '')
                        } p-0 fw-bolder text-${pnlPct > 0 ? 'success' :
                          (pnlPct < 0 ? 'danger' : 'muted')}`}
                        ></i>
                        {pnlPct}%
                      </span>
                    </td>
                    <td className="py-1 m-1">
                      <span className='fw-bold d-block fs-5 pe-3'>
                        {formatSecuritiesPrice(assetCode, (currentPrice * i.quantity).toFixed(2))}
                      </span>
                    </td>
                  </tr>
                )
              })
              :
              <tr>
                <td colSpan={8}>
                  <h2 className="text-center fs-5 pt-5 fw-medium">
                    {(isSecuritiesListReady || _.isEmpty(currentList)) ? 'There are no records to display' : 'Loading data...'}
                  </h2>
                </td>
              </tr>
            }
            </tbody>

          </table>
        </div>
      </div>
    </div>
  )
}

export default InvestmentsDetailsTable
