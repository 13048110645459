import React, {useEffect, useState} from 'react'
import {Card, CardBody, Col, Form, Row} from "reactstrap"
import {useTranslation} from "react-i18next"
import logoLight from '@metronic/_metronic/assets/media/auth/bg6.jpg'
import {router, usePage} from "@inertiajs/react"
import _ from "lodash"
import {toast, ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'

const bgPosition = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundSize: "cover",
  zIndex: '0'
}

const overlay = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  zIndex: '1'
}

const btnPrimary = {
  backgroundColor: '#007bff',
  color: 'white',
  border: 'none',
  padding: '10px 20px',
  fontSize: '16px',
  cursor: 'pointer',
  borderRadius: '4px',
  transition: 'background-color 0.3s',
}

const btnPrimaryHover = {
  backgroundColor: '#0056b3',
}

const btnClicked = {
  backgroundColor: '#7db9f4',
  color: 'white',
}

const TwoFactorContent = () => {
  const { t } = useTranslation()
  const [isMouseOnButton, setIsMouseOnButton] = useState(false)
  const [isButtonClicked, setIsButtonClicked] = useState(false)
  const [password, setPassword] = useState('')
  const {props} = usePage()
  const flash = props.flash

  const submitHandler = (e) => {
    e.preventDefault()
    const btn = e.target.querySelector('[type="submit"]')
    btn.disabled = true

    router.post('/2fa-verify', {one_time_password: password})

    btn.disabled = false
    setIsButtonClicked(false)
  }

  useEffect(() => {
    if (_.size(props.errors)) {
      for (let error in props.errors) {
        toast.error(props.errors[error])
      }
    } else if (flash) {
      if (!flash?.success) {
        toast.error(flash.message)
      } else {
        toast.success(flash.message)
      }
    }
  }, [props])

  return (
    <>
      <ToastContainer />
      <div className="d-flex flex-column flex-column-fluid position-relative">
        <img
          alt='bg'
          className="img-fluid theme-light-show w-100 h-100"
          src={logoLight}
          style={bgPosition}
        />
        <div style={overlay}></div>
        <Row className="justify-content-center align-items-center position-relative z-index-2 h-100">
          <Col sm={10} md={8} lg={6} xl={5}>
            <Card className="mt-4">
              <CardBody className="p-4">
                <div className="text-center mt-2">
                  <h5 className="text-primary">{t("Lock Screen")}</h5>
                  <p className="text-muted">{t("Enter your 2FA code to unlock the screen!")}</p>
                </div>
                {/*<div className="user-thumb text-center">*/}
                  {/*<img*/}
                  {/*  src={profileData.avatar_url ? profileData.avatar_url : default_avatar}*/}
                  {/*  className="rounded-circle img-thumbnail avatar-lg" alt="thumbnail"*/}
                  {/*/>*/}
                  {/*<h5 className="font-size-15 mt-3">{user.name}</h5>*/}
                {/*</div>*/}
                <div className="p-2 mt-4">
                  <Form onSubmit={submitHandler}>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="2fa_code">{t("2FA code")}</label>
                      <input
                        type="text"
                        className="form-control"
                        id="2fa_code"
                        placeholder={t("Enter code")}
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        autoComplete="off"
                        required
                      />
                    </div>
                    <div className="mb-2 mt-4">
                      <button
                        color="success"
                        className="w-100"
                        type="submit"
                        style={isButtonClicked ? {...btnPrimary, ...btnClicked} : isMouseOnButton ?
                          { ...btnPrimary, ...btnPrimaryHover } : btnPrimary}
                        onMouseEnter={() => setIsMouseOnButton(true)}
                        onMouseLeave={() => setIsMouseOnButton(false)}
                        onClick={() => {
                          setIsButtonClicked(true)
                        }}
                      >
                        {t("Unlock")}
                      </button>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default TwoFactorContent
