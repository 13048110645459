import React, {useContext, useState} from 'react'
import {Card, CardHeader, CardBody, Button} from "reactstrap"
import {router, usePage} from "@inertiajs/react"
import QRCode from "react-qr-code"
import {useTranslation} from "react-i18next"
import {copyToClipboard} from "@metronic/helpers/utils.jsx"
import {KTIcon} from "@metronic/_metronic/helpers/index"
import {toast} from "react-toastify"
import {ModalContext} from "@metronic/Components/Modal/index.jsx"
import Disable2faModal from "@metronic/Components/Modal/Disable2fa.jsx"

const TwoFaForm = () => {
  const twoFactorCode = usePage().props?.meta?.security_code
  const twoFactorCodeUrl = usePage().props?.meta?.two_fa_code_url
  const isTwoFactorEnable = usePage().props?.profile_data?.two_factor_auth_is_enabled
  const { t } = useTranslation()
  const [secret, setSecret] = useState('')
  const {openModal} = useContext(ModalContext)

  const generate2faHandler = () => {
    router.post('/generate-2fa', null, {preserveScroll: true})
  }

  const enable2faHandler = () => {
    if (!secret) {
      toast.error('Please enter verification code from 2FA app')
      return
    }

    router.post('/enable-2fa', {secret: secret}, {
      except: ['meta'],
      preserveScroll: true,
      onSuccess(r) {
        router.reload({only: ['profile_data', 'flash']})
        setSecret('')
      }
    })
  }

  const openDisabled2faModal = () => {
    openModal({
      title: 'Disable Google 2FA',
      content: <Disable2faModal />
    })
  }

  return (
    <>
      <Card>
        <CardHeader>
          <div className="card-title fw-bold">2FA</div>
        </CardHeader>
        <CardBody className="p-0">
          <div className="fs-4 p-5 pb-1 fw-bold text-gray-700" style={{ textAlign: 'justify' }}>
            Two factor authentication (2FA) strengthens access security by requiring two methods
            (also referred to as factors) to verify your identity. Two factor authentication protects against phishing,
            social engineering and password brute force attacks and secures your logins from attackers exploiting
            weak or stolen credentials.
          </div>

          {isTwoFactorEnable ?
            <div className="text-center p-5 fw-bold text-gray-700">
              <Button onClick={openDisabled2faModal} type='button' className="btn btn-sm btn-light-danger fw-bold w-100">
                {t("Disable 2FA")}
              </Button>
            </div>
            :
            <>
              <div className="text-center p-5 fw-bold text-gray-700">
                <Button onClick={generate2faHandler} type='button' color='primary' className="btn btn-sm fw-bold w-100">
                  {!twoFactorCode ? "Generate secret key to Enable 2FA" : "Re-generate secret key to Enable 2FA"}
                </Button>
              </div>

              {twoFactorCode ?
                <div className="p-5 pt-1">
              <span style={{textAlign: 'justify'}} className="fs-4 text-gray-700 fw-bold">
                {t("Please scan the code or enter the security code manually to get your verification code to enable 2FA.")}
              </span>
                  <h6 className="text-center mt-5 mb-3 text-gray-600">{t("Google Authenticator QRCode")}</h6>

                  <div className="text-center text-muted fw-bold fs-5 bg-secondary rounded-1 p-3 pt-5">
                    <div style={{height: "auto", margin: "0 auto", maxWidth: 200, width: "100%"}}>
                      <QRCode
                        size={256}
                        style={{height: "auto", maxWidth: "100%", width: "100%"}}
                        value={twoFactorCodeUrl}
                        viewBox={`0 0 256 256`}
                      />
                    </div>

                    <div className='separator border-body d-flex flex-center mt-8 mb-6'>
                      <span className='bg-body fw-bold fs-6 fw-semibold text-muted px-3 rounded-3'>or</span>
                    </div>

                    <div className="fs-5 fw-bold text-gray-600">
                  <span onClick={() => copyToClipboard(twoFactorCode)} className="cursor-pointer px-3 py-1">
                    {twoFactorCode}
                    <KTIcon iconName='copy' className='fs-3 ps-2'/>
                  </span>
                    </div>
                  </div>

                  <div className="mt-8">
                    <span className="text-gray-800 fw-bold">{t("2FA code")}</span>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t("Enter verification code from 2FA app here")}
                        value={secret}
                        onChange={e => setSecret(e.target.value)}
                      />
                      <button
                        type="button"
                        className="input-group-text btn btn-light-primary border border-light-subtle fw-bold"
                        onClick={enable2faHandler}
                      >
                        Verify
                      </button>
                    </div>
                  </div>
                </div> : null
              }
            </>
          }
        </CardBody>
      </Card>
    </>
  )
}

export default TwoFaForm
