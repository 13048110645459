import {get, post} from "@metronic/helpers/api_helper.jsx"

export function selectListData(name, args) {
  let data = args ? {name: name, args: args} : {name: name}

  return post('/select-list-data', data)
}

export function staticData(name, args) {
  let data = args ? {name: name, args: args} : {name: name}

	// return api.post('/spa/static-data', data)
}
